import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Popover, Overlay } from 'react-bootstrap';
import { Icon, ICONS } from 'components/Icons';
import './InlinePopoverCard.scss';

export default function InlinePopoverCard({
  show,
  onHide,
  placement,
  title,
  icon,
  target,
  children,
}) {
  const popoverRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    const domNode = ReactDOM.findDOMNode(popoverRef.current); // Get the DOM node
    if (domNode && !domNode.contains(event.target)) {
      onHide(event);
    }
  };

  return show ? (
    <Overlay
      show={show}
      target={target.current}
      placement={placement || 'bottom'}
      container={target.current}
    >
      <Popover ref={popoverRef} className="popover">
        <div className="popover-data d-flex flex-row align-items-center">
          {icon && (
            <div className="align-self-start mt-1 mr-3">
              <Icon icon={icon} size={16} className="popover-icon" />
            </div>
          )}
          <div className="d-flex flex-column flex-grow-1">
            <div className="d-flex flex-row align-items-center justify-content-end">
              {title && (
                <div className="flex-fill popover-main-title mr-3">{title}</div>
              )}
              <button type="button" className="close-btn" onClick={onHide}>
                <Icon icon={ICONS.CLOSE} size={10} className="close-icon" />
              </button>
            </div>
            <div className="popover-main-description mt-2">{children}</div>
          </div>
        </div>
      </Popover>
    </Overlay>
  ) : null;
}
