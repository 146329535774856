import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
// import { connectRouter } from 'connected-react-router';

import app from './app';
import adminMails from './adminMails';
import adminPoints from './adminPoints';
import groups from './group';
import hint from './hint';
import keyboardShortcuts from './keyboardShortcuts';
import modals from './modal';
import page from './page';
import points from './point';
import popover from './popover';
import portfolios from './portfolio';
import search from './search';
import session from './session';
import sources from './sources';
import tags from './tag';
import user from './user';
import walkthrough from './walkthrough';
import mediaLogs from './mediaLogs';
import weeklyGoals from './weeklyGoals';
import blogs from './blog';
import helps from './help';
import bottomSheet from './bottomSheet';
import profileImage from './profileImage';
import dashboard from './dashboard';
import newsClub from './newsClub';
import classRoom from './classRooms';
import newsletter from './newsletter';
import writingPrompts from './writingPrompts';
import writings from './writings';
import writingVersions from './writingVersions';
import writingReviews from './writingReviews';
import researchPrompts from './researchPrompts';
import researchSubmissions from './researchSubmissions';
import researchReviews from './researchReviews';
import classroomGroups from './classroomGroups';
import topic from './topic';
import pendingActions from './pendingActions';
import subscriptions from './subscriptions';

// export default combineReducers({
export default (history) =>
  combineReducers({
    // router: connectRouter(history),
    app,
    adminMails,
    adminPoints,
    groups,
    hint,
    keyboardShortcuts,
    modals,
    page,
    points,
    popover,
    portfolios,
    routing: routerReducer,
    search,
    session,
    sources,
    tags,
    user,
    walkthrough,
    mediaLogs,
    weeklyGoals,
    blogs,
    helps,
    bottomSheet,
    profileImage,
    dashboard,
    newsClub,
    classRoom,
    newsletter,
    writingPrompts,
    writings,
    writingVersions,
    writingReviews,
    researchPrompts,
    researchSubmissions,
    researchReviews,
    classroomGroups,
    topic,
    pendingActions,
    subscriptions,
  });
