/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Switch, Redirect, Route, Link } from 'react-router-dom';
import Loadable from 'react-loadable';
import Spin from './components/elements/atoms/loading';

const Loading = () => <Spin spinning className="py-3" />;
const WithoutLoadingComponents = () => <div />;

const AuthRoute = Loadable({
  loader: () => import('./routes/AuthRoute'),
  loading: Loading,
});

const UserFormWrapper = Loadable({
  loader: () => import('./routes/User/UserFormWrapper'),
  loading: WithoutLoadingComponents,
});

const LoggedOutPage = Loadable({
  loader: () => import('./routes/User/LoggedOut/LoggedOutPage'),
  loading: WithoutLoadingComponents,
});

const LoggedOutPageHome = Loadable({
  loader: () => import('./routes/User/LoggedOutHome/LoggedOutPage'),
  loading: WithoutLoadingComponents,
});

const ReadersPage = Loadable({
  loader: () => import('./routes/User/ReadersPage/ReadersPage'),
  loading: WithoutLoadingComponents,
});

const LandingPage = Loadable({
  loader: () => import('./routes/LandingPage/LandingPage'),
  loading: Loading,
});

const WelcomePage = Loadable({
  loader: () => import('./routes/User/Welcome/WelcomePage'),
  loading: Loading,
});
const Portfolio = Loadable({
  loader: () => import('./routes/Portfolio/PortfolioContainer'),
  loading: Loading,
});
const Collection = Loadable({
  loader: () => import('./routes/Collection/CollectionComponent'),
  loading: Loading,
});
const CollectionCard = Loadable({
  loader: () => import('./components/cards/CollectionCard/CollectionCard'),
  loading: Loading,
});
const Point = Loadable({
  loader: () => import('./routes/Point/PointPageContainer/PointPageContainer'),
  loading: Loading,
});
const PointContainer = Loadable({
  loader: () => import('./components/cards/Point/PointContainer'),
  loading: Loading,
});

const PricingPage = Loadable({
  loader: () => import('./routes/User/PricingPage/PricingPage'),
  loading: WithoutLoadingComponents,
});

const DetailedPricingPage = Loadable({
  loader: () => import('./routes/User/DetailedPricingPage/DetailedPricingPage'),
  loading: WithoutLoadingComponents,
});

// import ReadPointsContainer from "./components/ReadPoints/ReadPointsContainer"

const AboutPage = Loadable({
  loader: () => import('./routes/About/AboutPage'),
  loading: Loading,
});

const UseCaseforCommunity = Loadable({
  loader: () => import('./routes/About/UseCaseForCommunity'),
  loading: Loading,
});

const UseCaseForCreators = Loadable({
  loader: () => import('./routes/About/UseCaseForCreators'),
  loading: Loading,
});

const PDFViewPage = Loadable({
  loader: () => import('./routes/PDFView/PDFViewPage'),
  loading: Loading,
});
const AddPointPopup = Loadable({
  loader: () => import('./routes/Chrome/AddPointPopup'),
  loading: Loading,
});
const Feed = Loadable({
  loader: () => import('./routes/Feed'),
  loading: Loading,
});
const Profile = Loadable({
  loader: () => import('./routes/Profile/Profile'),
  loading: Loading,
});
// const AdminContainer = Loadable({
//   loader: () => import('./routes/Admin/AdminContainer'),
//   loading: Loading,
// });
const Group = Loadable({
  loader: () => import('./routes/Group/GroupsMainPage/GroupsMainPage'),
  loading: Loading,
});
const GroupDetails = Loadable({
  loader: () => import('./routes/Group/GroupDetails/GroupDetails'),
  loading: Loading,
});
const CreateGroup = Loadable({
  loader: () => import('./routes/Group/GroupDetails/Tabs/CreateGroup'),
  loading: Loading,
});
const GroupSettings = Loadable({
  loader: () => import('./routes/Group/GroupDetails/Tabs/GroupSettings'),
  loading: Loading,
});
const ImageCropPopup = Loadable({
  loader: () => import('./routes/Chrome/ImageCropPopup'),
  loading: Loading,
});
const ViewWithPoints = Loadable({
  loader: () => import('./routes/Chrome/ViewWithPoints/ViewWithPoints'),
  loading: Loading,
});
const PerformLogin = Loadable({
  loader: () => import('./routes/Chrome/PerformLogin/PerformLogin'),
  loading: Loading,
});
const ManageClaim = Loadable({
  loader: () => import('./routes/Chrome/ManageClaim/ManageClaim'),
  loading: Loading,
});
const RefreshToken = Loadable({
  loader: () => import('./routes/Chrome/RefreshToken/RefreshToken'),
  loading: Loading,
});
const ArticleQuiz = Loadable({
  loader: () => import('./routes/Chrome/ArticleQuiz/ArticleQuiz'),
  loading: Loading,
});
const MessagePortal = Loadable({
  loader: () => import('./routes/Admin/pages/MessagePortal/MessagePortal'),
  loading: Loading,
});
const SourceContainer = Loadable({
  loader: () => import('./routes/Admin/pages/Source/SourceContainer'),
  loading: Loading,
});

const AdminPoints = Loadable({
  loader: () => import('./routes/Admin/pages/Points'),
  loading: Loading,
});
const SettingsContainer = Loadable({
  loader: () => import('./routes/Settings/SettingsContainer'),
  loading: Loading,
});
const PaymentsSuccessContainer = Loadable({
  loader: () =>
    import('./routes/Payments/PaymentsSuccess/PaymentsSuccessContainer'),
  loading: Loading,
});
const PaymentsFailureContainer = Loadable({
  loader: () =>
    import('./routes/Payments/PaymentsFailure/PaymentsFailureContainer'),
  loading: Loading,
});
const Score = Loadable({
  loader: () => import('./routes/Score/ScoreComponent'),
  loading: Loading,
});
const HelpsContainer = Loadable({
  loader: () => import('./routes/Helps/Helps'),
  loading: Loading,
});
const Help = Loadable({
  loader: () => import('./routes/Help/Help'),
  loading: Loading,
});
const ContactContainer = Loadable({
  loader: () => import('./routes/Contact/ContactContainer'),
  loading: Loading,
});
const Search = Loadable({
  loader: () => import('./routes/Search/Search'),
  loading: Loading,
});

const PointEmbed = Loadable({
  loader: () => import('./routes/Embed/Point'),
  loading: Loading,
});

const CollectionEmbed = Loadable({
  loader: () => import('./routes/Embed/Collection'),
  loading: Loading,
});

const Test = Loadable({
  loader: () => import('./routes/Test/Test'),
  loading: Loading,
});

const Home = Loadable({
  loader: () => import('./routes/Home/Home'),
  loading: Loading,
});

const MyInvites = Loadable({
  loader: () => import('./routes/MyInvites/MyInvites'),
  loading: Loading,
});

const ImportPointsPopup = Loadable({
  loader: () => import('./routes/Popup/ImportPoints/ImportPoints'),
  loading: Loading,
});

const IFrame = Loadable({
  loader: () => import('./routes/Iframe/Iframe'),
  loading: Loading,
});

const TestMedia = Loadable({
  loader: () => import('./routes/TestMedia/TestMedia'),
  loading: Loading,
});

const Blogs = Loadable({
  loader: () => import('./routes/Blogs/Blogs'),
  loading: Loading,
});

const Blog = Loadable({
  loader: () => import('./routes/Blog/Blog'),
  loading: Loading,
});

const SourcesPage = Loadable({
  loader: () => import('./routes/Sources/SourcesContainer'),
  loading: Loading,
});

const TestCohort = Loadable({
  loader: () => import('./routes/TestCohort/TestCohort'),
  loading: Loading,
});

const Writer = Loadable({
  loader: () => import('./routes/Writer/Writer'),
  loading: Loading,
});

const ResearchPrompt = Loadable({
  loader: () => import('./routes/ResearchPrompt/ResearchPrompt'),
  loading: Loading,
});

const Reading = Loadable({
  loader: () => import('./routes/Reading/Reading'),
  loading: Loading,
});

const PastTalks = Loadable({
  loader: () => import('./routes/PastTalks/PastTalksContainer'),
  loading: Loading,
});

const NewsClub = Loadable({
  loader: () => import('./routes/NewsClub/NewsClubContainer'),
  loading: Loading,
});

const ExtensionUninstall = Loadable({
  loader: () => import('./routes/ExtensionUninstall/ExtensionUninstall'),
  loading: Loading,
});

const TestimonialsPage = Loadable({
  loader: () => import('./routes/Testimonials/Testimonials'),
  loading: Loading,
});

const LevelsPage = Loadable({
  loader: () => import('./routes/Levels/Levels'),
  loading: Loading,
});

const SchoolsPage = Loadable({
  loader: () => import('./routes/Schools/Schools'),
  loading: Loading,
});

const NewsChallengePage = Loadable({
  loader: () => import('./routes/NewsChallenge/NewsChallenge'),
  loading: Loading,
});

class MainRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <AuthRoute
          exact
          path="/"
          // component={Home}
          // render={() => <Redirect to="/portfolio/averpoint/channel" />}
          component={Portfolio}
        />
        {/* Auth Routes */}
        <Redirect strict from="/login" to="/auth/login" />
        <Redirect strict from="/signUp" to="/auth/signUp" />
        <Redirect strict from="/forgot-password" to="/auth/forgot-password" />
        <Redirect strict from="/forgot-username" to="/auth/forgot-username" />
        <Redirect
          strict
          from="/postRegister/:flag"
          to="/auth/postRegister/:flag"
        />
        <Redirect
          strict
          from="/reset-password/:userId/:token"
          to="/auth/reset-password/:userId/:token"
        />
        <Route path="/readers" component={ReadersPage} />
        <Route path="/pricing/:planName" component={DetailedPricingPage} />
        <Route path="/pricing" component={PricingPage} />
        <Route path="/home/intro" component={LoggedOutPageHome} />
        <Route path="/home/:feature" component={LoggedOutPage} />
        <Route path="/auth/loggedOut" component={LoggedOutPage} />
        <Route path="/auth" component={UserFormWrapper} />
        <Route path="/testimonials" component={TestimonialsPage} />
        <AuthRoute path="/welcome" component={WelcomePage} />

        {/* Embed Routes */}
        <Route
          path="/CollectionCard/:id"
          render={(props) => <CollectionCard {...props} cardType="embed" />}
        />
        <Route
          path="/PointCard/:id"
          render={(props) => <PointContainer {...props} cardType="embed" />}
        />

        <Route
          path="/embed/:type/:id"
          render={(props) =>
            props.match.params.type === 'point' ? (
              <PointEmbed {...props} />
            ) : (
              <CollectionEmbed />
            )
          }
        />
        {/* Public and Private Pages */}
        <Route path="/about" component={AboutPage} />
        <Route path="/forCommunity" component={UseCaseforCommunity} />
        <Route path="/forCreators" component={UseCaseForCreators} />
        <Route path="/pdfview/:filename" component={PDFViewPage} />
        <AuthRoute path="/collection/:collectionId" component={Collection} />
        <Route path="/survey/:collectionId" component={Collection} />
        <Route path="/testMedia/:collectionId" component={TestMedia} />
        <Route path="/contact" component={ContactContainer} />
        <AuthRoute exact path="/groups/(me|featured)?" component={Group} />
        <AuthRoute exact path="/groups/create" component={CreateGroup} />
        <AuthRoute
          exact
          path="/groups/:id/settings"
          component={GroupSettings}
        />
        <AuthRoute path="/groups/:id" component={GroupDetails} />
        <Route path="/help-page" component={Help} />
        <Route path="/help" component={HelpsContainer} />
        <Route path="/landing/:focus" component={LandingPage} />
        <Route path="/classroom" component={LandingPage} />
        <Route path="/schools" component={SchoolsPage} />
        <AuthRoute path="/messages" component={MessagePortal} />
        {/* <Route path="/profile/:username" component={Profile}/> */}
        <AuthRoute path="/Point/:id" component={Point} />
        <AuthRoute path="/portfolio/:username" component={Portfolio} exact />
        <AuthRoute path="/portfolio/:username/:tab" component={Portfolio} />
        <AuthRoute path="/portfolio/" component={Portfolio} exact />
        <AuthRoute path="/popup/addPoint" component={AddPointPopup} />
        <AuthRoute path="/popup/imageCrop" component={ImageCropPopup} />
        <AuthRoute path="/popup/viewWithPoints" component={ViewWithPoints} />
        <AuthRoute path="/popup/performLogin" component={PerformLogin} />
        <AuthRoute path="/popup/manageClaim" component={ManageClaim} />
        <AuthRoute path="/popup/refreshToken" component={RefreshToken} />
        <AuthRoute path="/popup/articleQuiz" component={ArticleQuiz} />
        <AuthRoute path="/score" component={Score} />
        <Route path="/search" component={Search} />
        <Route path="/pastTalks" component={PastTalks} />
        <Route path="/newsClub" component={NewsClub} />
        <AuthRoute path="/settings" component={SettingsContainer} />
        <AuthRoute
          path="/subscription/handleSuccess"
          component={PaymentsSuccessContainer}
        />
        <AuthRoute
          path="/subscription/handleCancel"
          component={PaymentsFailureContainer}
        />
        <AuthRoute path="/adminsources" component={SourceContainer} exact />
        <AuthRoute path="/sources" component={SourcesPage} exact />
        <AuthRoute
          exact
          path="/sources"
          component={() => <Group isSource={true} />}
        />
        <AuthRoute
          path="/sources/:id/:displayPage?"
          component={(props) => <GroupDetails isSource={true} {...props} />}
        />
        <Route path="/blogs" component={Blogs} />
        <Route path="/blog" component={Blog} />
        <Redirect strict from="/home" to="/portfolio" />

        {/* Admin Routes */}
        {/* <AuthRoute path="/admin" component={AdminContainer} /> */}
        <AuthRoute path="/points" component={AdminPoints} />
        <AuthRoute path="/test" component={Test} />
        <AuthRoute path="/my-invites" component={MyInvites} />
        <AuthRoute path="/popup/importPoints" component={ImportPointsPopup} />
        <Route path="/iframe" component={IFrame} />
        <AuthRoute path="/test-cohort" component={TestCohort} />
        <AuthRoute path="/writer" component={Writer} />
        <AuthRoute path="/researchPrompt" component={ResearchPrompt} />
        <AuthRoute path="/reading" component={Reading} />
        <Route path="/extension-uninstall" component={ExtensionUninstall} />
        <Route path="/levels" component={LevelsPage} />
        <Route path="/news-challenge" component={NewsChallengePage} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    );
  }
}

export default MainRoutes;
