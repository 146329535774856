import axios from 'axios';
import qs from 'qs';
// import { browserHistory } from "react-router-dom"
import { push } from 'react-router-redux';
import * as Actions from 'redux/actions';

import { UPDATE_PORTFOLIO } from './portfolio';
import { clearSearch } from './search';
import { loadEarnedBadges, loadNotEarnedBadges } from './weeklyGoals';
import { toast } from 'components/elements/molecules/Toast/Toast';
import { loadMediaChannel } from './mediaLogs';
import { resetCourses } from './classRooms';
import { resetWritingPrompts } from './writingPrompts';

export const MANUAL_LOGIN_USER = 'MANUAL_LOGIN_USER';
export const LOGIN_SUCCESS_USER = 'LOGIN_SUCCESS_USER';
export const LOGIN_ERROR_USER = 'LOGIN_ERROR_USER';
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_SUCCESS_USER = 'SIGNUP_SUCCESS_USER';
export const SIGNUP_ERROR_USER = 'SIGNUP_ERROR_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_SUCCESS_USER = 'LOGOUT_SUCCESS_USER';
export const LOGOUT_ERROR_USER = 'LOGOUT_ERROR_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_SUCCESS_USER = 'REGISTER_SUCCESS_USER';
export const REGISTER_ERROR_USER = 'REGISTER_ERROR_USER';
export const FACEBOOK_LOGIN_USER = 'FACEBOOK_LOGIN_USER';
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';
export const NOTIFICATIONS_SEEN = 'NOTIFICATIONS_SEEN';
export const NEW_NOTIFICATION = 'NEW_NOTIFICATION';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ANOTHER_USER = 'UPDATE_ANOTHER_USER';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const SOCIAL_LOGIN_SUCCESS = 'SOCIAL_LOGIN_SUCCESS';
export const SOCIAL_LOGIN_ERROR = 'SOCIAL_LOGIN_ERROR';
export const SEND_PASSWORD_RESET = 'SEND_PASSWORD_RESET';
export const SEND_PASSWORD_RESET_SUCCESS = 'SEND_PASSWORD_RESET_SUCCESS';
export const SEND_PASSWORD_RESET_ERROR = 'SEND_PASSWORD_RESET_ERROR';
export const NEW_SESSION = 'NEW_SESSION';
export const SYNC_SESSION = 'SYNC_SESSION';
export const SYNC_SESSION_SUCCESS = 'SYNC_SESSION_SUCCESS';
export const SYNC_SESSION_ERROR = 'SYNC_SESSION_ERROR';
export const TOP_USERS = 'TOP_USERS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_COMMUNITY_USERS = 'GET_ALL_COMMUNITY_USERS';
export const COLLECTION_CLEAR = 'COLLECTION_CLEAR';
export const PORTFOLIO_CLEAR = 'PORTFOLIO_CLEAR';
export const POINT_CLEAR = 'POINT_CLEAR';
export const LOGIN_USERNAME_REQUIRE = 'LOGIN_USERNAME_REQUIRE';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ACTIVATE_STUDENT_ROLE = 'ACTIVATE_STUDENT_ROLE';
export const ACTIVATE_TEACHER_ROLE = 'ACTIVATE_TEACHER_ROLE';
export const ACTIVATE_VOCABULARY = 'ACTIVATE_VOCABULARY';
export const ADD_GOOGLE_DETAILS = 'ADD_GOOGLE_DETAILS';
export const LOAD_UNVERIFIED_USERS = 'LOAD_UNVERIFIED_USERS';
export const REMOVE_UNVERIFIED_USER = 'REMOVE_UNVERIFIED_USER';
export const UPDATE_DEFAULT_LMS_APP = 'UPDATE_DEFAULT_LMS_APP';
export const SET_IMPERSONATE_USER = 'SET_IMPERSONATE_USER';

// "Log In" action creators
function beginLogin() {
  return { type: MANUAL_LOGIN_USER };
}

export function loginSuccess(data) {
  return {
    type: LOGIN_SUCCESS_USER,
    data,
  };
}

function loginError() {
  return { type: LOGIN_ERROR_USER };
}

// "Log Out" action creators
function beginLogout() {
  return { type: LOGOUT_USER };
}

function logoutSuccess() {
  return { type: LOGOUT_SUCCESS_USER };
}
function clearCollection() {
  return {
    type: COLLECTION_CLEAR,
  };
}

function clearPortfolio() {
  return { type: PORTFOLIO_CLEAR };
}

function clearPoint() {
  return { type: POINT_CLEAR };
}
function logoutError() {
  return { type: LOGOUT_ERROR_USER };
}

// "Register" action creators
function beginRegister() {
  return { type: REGISTER_USER };
}

function registerSuccess() {
  return { type: REGISTER_SUCCESS_USER };
}

function registerError() {
  return { type: REGISTER_ERROR_USER };
}

function notificationsSeen(notifications) {
  return {
    type: NOTIFICATIONS_SEEN,
    notifications,
  };
}

export function newNotification(notification) {
  return {
    type: NEW_NOTIFICATION,
    notification,
  };
}

export function userUpdate(user, sendMessage = true) {
  // notify CE to update user
  if (sendMessage) {
    window.postMessage(
      { type: 'averpoint:user:update' },
      window.location.origin,
    );
  }

  return {
    type: UPDATE_USER,
    user,
  };
}

export function beginPasswordReset() {
  return {
    type: SEND_PASSWORD_RESET,
  };
}

export function passwordResetSuccess() {
  return {
    type: SEND_PASSWORD_RESET_SUCCESS,
  };
}

export function passwordResetError(error) {
  return {
    type: SEND_PASSWORD_RESET_ERROR,
    error,
  };
}

export function startSocialLogin() {
  return {
    type: SOCIAL_LOGIN,
  };
}

export function socialLoginSuccess(user) {
  return {
    type: SOCIAL_LOGIN_SUCCESS,
    user,
  };
}

export function socialLoginError(error) {
  return {
    type: SOCIAL_LOGIN_SUCCESS,
    error,
  };
}

export function allUserList(userList) {
  return {
    type: GET_ALL_USERS,
    userList,
  };
}

export function allCommunityUserList(userList) {
  return {
    type: GET_ALL_COMMUNITY_USERS,
    userList,
  };
}

export function activateStudent() {
  // notify CE to update user
  window.postMessage({ type: 'averpoint:user:update' }, window.location.origin);

  return {
    type: ACTIVATE_STUDENT_ROLE,
  };
}

export function activateTeacher() {
  // notify CE to update user
  window.postMessage({ type: 'averpoint:user:update' }, window.location.origin);

  return {
    type: ACTIVATE_TEACHER_ROLE,
  };
}

export function activateVocabulary() {
  return {
    type: ACTIVATE_VOCABULARY,
  };
}

export function hasInvite() {
  return {
    type: 'HAS_INVITE',
  };
}

export function refreshToken(accessToken) {
  return {
    type: 'REFRESH_TOKEN',
    accessToken: accessToken,
  };
}

export function loadUnverifiedUsers(unverifiedUsersByRole) {
  return {
    type: LOAD_UNVERIFIED_USERS,
    unverifiedUsersByRole,
  };
}

export function removeUnverifiedUsers(userId, role) {
  return {
    type: REMOVE_UNVERIFIED_USER,
    userId,
    role,
  };
}

export function updateDefaultLMSApp(lmsApp) {
  return {
    type: UPDATE_DEFAULT_LMS_APP,
    lmsApp,
  };
}

export function setImpersonateUser(username, userId) {
  return {
    type: SET_IMPERSONATE_USER,
    username,
    userId,
  };
}

function makeUserRequest(method, data, api = '/api/auth/login') {
  // returns a Promise
  return axios({
    method,
    url: api,
    data,
  });
}

function makeSocialAuthRequest(method, data, api = '/api/auth/login') {
  // returns a Promise
  return axios({
    method,
    url: api,
    data,
  });
}

// Example of an Async Action Creator
// http://redux.js.org/docs/advanced/AsyncActions.html
export function manualLogin(
  data,
  successLocation, // path to redirect to upon successful log in
) {
  return (dispatch) => {
    dispatch(beginLogin());
    dispatch(clearCollection());
    dispatch(clearPortfolio());
    dispatch(clearPoint());
    dispatch(clearSearch());
    dispatch(loadMediaChannel({}));

    return makeUserRequest('post', data, '/api/auth/login')
      .then((response) => {
        if (response.data.success) {
          response.data.currentUser = response.data.user;
          dispatch(loginSuccess(response.data));

          // if (successLocation.pathname === "/") {
          // 	successLocation.pathname = "/portfolio/"+response.data.user.username;
          // }
          // 	if(successLocation.pathname) dispatch(push(successLocation));
        } else {
          dispatch(loginError());
          const loginMessage = response.data.message;
          return loginMessage;
        }
      })
      .catch((response) => {
        const { data } = response.response;
        throw data.message;
      });
  };
}

// Handle successful login on AWS Cognito
export function cognitoLogin(data) {
  return (dispatch) => {
    dispatch(beginLogin());
    dispatch(clearCollection());
    dispatch(clearPortfolio());
    dispatch(clearPoint());
    dispatch(clearSearch());
    dispatch(loadMediaChannel({}));

    Actions.getActiveSubscriptions(null, dispatch);

    return makeUserRequest('post', data, '/api/auth/login/successful')
      .then((response) => {
        if (response && response.data && response.data.success) {
          response.data.currentUser = response.data.user;
          dispatch(loginSuccess(response.data));
        } else {
          dispatch(loginError());
          const loginMessage =
            response && response.data
              ? response.data.message
              : 'Failed to login';
          return loginMessage;
        }
      })
      .catch((response) => {
        const data =
          response && response.response ? response.response.data : {};
        throw data.message || 'Failed to login';
      });
  };
}

// Example of an Async Action Creator
// http://redux.js.org/docs/advanced/AsyncActions.html
export function manualLogout(successPath) {
  // Clear any variables persisting on local storage
  localStorage.removeItem('recentTags');
  localStorage.removeItem('recentCollectionId');

  // Clear redux state
  return (dispatch) => {
    dispatch(clearCollection());
    dispatch(clearPortfolio());
    dispatch(clearPoint());
    dispatch(logoutSuccess());
    dispatch(clearSearch());
    dispatch(loadEarnedBadges([]));
    dispatch(loadNotEarnedBadges([]));
    dispatch(loadMediaChannel([]));
    dispatch(resetCourses());
    dispatch(resetWritingPrompts());

    if (successPath) {
      dispatch(push(successPath));
    }
  };
}

export function manualRegister(data) {
  return (dispatch) => {
    dispatch(beginRegister());

    return makeUserRequest('post', data, '/api/auth/register')
      .then((response) => {
        if (response.data.success) {
          dispatch(registerSuccess());
          dispatch(push('postRegister/email-sent'));
        } else {
          dispatch(registerError());
          const registerMessage = response.data.message;
          return registerMessage;
        }
      })
      .catch((response) => {
        if (response instanceof Error) {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', response.message);
        }
      });
  };
}

export function cognitoSignup(data) {
  return (dispatch) => {
    dispatch(beginRegister());

    return makeUserRequest('post', data, '/api/auth/register/successful')
      .then((response) => {
        if (response.data.success) {
          dispatch(registerSuccess());
          // dispatch(push('postRegister/success'));
        } else {
          dispatch(registerError());
          const registerMessage = response.data.message;
          return registerMessage;
        }
      })
      .catch((response) => {
        if (response instanceof Error) {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', response.message);
        }
      });
  };
}

export function requestAccess(data) {
  return (dispatch) =>
    //dispatch(beginRegister())
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/averPointCustomerContacts/requestAccess',
        data: {
          requesterEmail: data.email,
          requesterName: data.name,
          requestFor: 'signUp',
        },
      })
        .then((response) => {
          if (response.data.success) {
            //dispatch(registerSuccess())
            //dispatch(push("postRegister/email-sent"));
            resolve();
          } else {
            //dispatch(registerError())
            //let registerMessage = response.data.message
            reject(response.data.message);
            return response.data.message;
          }
        })
        .catch((response) => {
          if (response instanceof Error) {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', response.message);
          }
        });
    });
}

export function restoreSession(token, dispatch) {
  setTimeout(function () {
    dispatch({ type: SYNC_SESSION });

    if (token) {
      axios
        .get('/api/auth/users/me', {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then(
          (resp) => {
            const { data } = resp;
            data.currentUser = data.user;
            dispatch({ type: SYNC_SESSION_SUCCESS, data });
          },
          (err) => {
            dispatch({ type: SYNC_SESSION_ERROR });
          },
        );
    } else {
      dispatch({ type: SYNC_SESSION_ERROR });
    }
  }, 100);
}

export function startNewSession(dispatch) {
  setTimeout(function () {
    dispatch({ type: NEW_SESSION });
  }, 100);
}

export function getNotifications() {
  return (dispatch, getState) => {
    console.log('get notifications called');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/notifications/getNotifications',
      })
        .then((response) => {
          // console.log('Get notifications responded successfully');
          const { data } = response;

          dispatch({
            type: NOTIFICATIONS_SEEN,
            notifications: data.notifications,
          });

          resolve();
        })
        .catch((response) => {
          console.log('Get notifications responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function userViewedNotifications() {
  return (dispatch, getState) => {
    console.log('user viewed notifications seen');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/notifications/userViewedNotifications',
      })
        .then((response) => {
          console.log('User viewed notifications responded successfully');
          console.log(response);
          const { data } = response;

          dispatch(notificationsSeen(data.notifications));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('User viewed notifications responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateUserPhotoLicenseTerms(user) {
  return (dispatch, getState) => {
    console.log('limited update of user');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserPhotoLicenseTerms',
        data: {
          user,
        },
      })
        .then((response) => {
          console.log('User update responded successfully');
          console.log(response);
          const { data } = response;

          dispatch(userUpdate(data.user));
          dispatch({
            type: UPDATE_PORTFOLIO,
            portfolio: data.updatedPortfolio,
          });

          resolve(data.point);
        })
        .catch((response) => {
          console.log('user update responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateUserProfile(profile) {
  return (dispatch, getState) => {
    console.log('updating user profile', profile);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserProfile',
        data: {
          ...profile,
          // firstName: profile.firstName,
          // lastName: profile.lastName,
          // type: profile.type,
          // description: profile.description,
          // homeCity: profile.homeCity,
          // currentCity: profile.currentCity,
          // organizationName: profile.organizationName,
          // tags: profile.tags,
          // education: profile.education,
          // experience: profile.experience,
          // expertise: profile.expertise,
          // interests: profile.interests,
          // sector: profile.sector,
        },
      })
        .then((response) => {
          console.log('update User Profile responded successfully');
          console.log(response);
          const { data } = response;

          dispatch(userUpdate(data.user));

          resolve(data.user);
        })
        .catch((response) => {
          console.log('update user profile responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateUserRoleStatus(updatedRole) {
  return (dispatch, getState) => {
    console.log('updating user role', updateUserRoleStatus);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserRoleStatus',
        data: {
          updatedRole,
        },
      })
        .then((response) => {
          console.log('update User Role status successfully');
          console.log(response);
          const { data } = response;

          try {
            dispatch(userUpdate(data.user));
          } catch (error) {
            console.error('Error while updating user', error);
          }

          resolve(data.user);
        })
        .catch((response) => {
          console.log('update user role status error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateMediaSettings(
  sourceSettingsAnalyzeAll,
  viewSourcesWithPoints,
) {
  return (dispatch, getState) => {
    console.log(
      'updating sourceSettingsAnalyzeAll, viewSourcesWithPoints',
      sourceSettingsAnalyzeAll,
      viewSourcesWithPoints,
    );

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserProfile',
        data: {
          sourceSettingsAnalyzeAll,
          viewSourcesWithPoints,
        },
      })
        .then((response) => {
          console.log(
            'update User Profile Settings responded successfully',
            response,
          );
          resolve();
        })
        .catch((response) => {
          toast('Failed to update the settings. Please try again letter', {
            type: 'error',
          });
          reject(response);
        });
    });
  };
}

export function sendPasswordResetMail(data) {
  return (dispatch) => {
    dispatch(beginPasswordReset());
    return axios.post('/api/auth/forgot-password', data).then(
      () => dispatch(passwordResetSuccess()),
      (err) => {
        dispatch(passwordResetError(err.response.data.message));
        throw err.response.data.message;
      },
    );
  };
}

export function sendUsernameEmail(data) {
  return (dispatch) =>
    axios.post('/api/auth/forgot-username', data).then(
      (resp) => resp.data,
      (err) => {
        throw err.message;
      },
    );
}

export function checkEmail(data) {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/check-email', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function resetPassword(userId, token, password) {
  return (dispatch) =>
    axios
      .post(`/api/auth/reset-password`, {
        userId,
        token,
        password,
      })
      .catch((err) => {
        throw err.data.message;
      });
}

// user to reset password
export function validateToken(userId, token) {
  return (dispatch) =>
    axios
      .post(`/api/validate-token`, {
        userId,
        token,
      })
      .catch((err) => {
        dispatch(push('/'));
      });
}

export function getTopUsers() {
  return (dispatch) =>
    axios.get('/api/users/top').then((resp) => {
      dispatch({
        type: TOP_USERS,
        users: resp.data.users,
      });
    });
}

export function getUserInvites() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/users/getUserInvites',
      })
        .then((response) => {
          dispatch({
            type: 'UPDATE_POINTS',
            user: getState().user,
            updatedPoints: response.data.invitedPoints,
          });
          dispatch({
            type: 'INVITED_GROUPS',
            data: response.data.groups,
          });
        })
        .catch((response) => {
          console.log('getAll Users responded error');
          console.log(response);
          reject(response);
        });
    });
}

export function getAllUsers(searchTerm) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/users/getAllUsers',
        params: { searchTerm },
      })
        .then((response) => {
          console.log('response ON actions', response);
          let data = response.data;
          dispatch(allUserList(data));
          resolve(data);
        })
        .catch((response) => {
          console.log('getAll Users responded error');
          console.log(response);
          reject(response);
        });
    });
}

export function inviteAverpoint(emails, message) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/inviteUsers',
        data: {
          emails,
          message,
        },
      })
        .then((response) => {
          console.log('InvitePoint responded successfully');
          console.log(response);
          let data = response.data;
          resolve(data);
        })
        .catch((response) => {
          console.log('InvitePoint responded error');
          console.log(response);
          //     dispatch(pointError(response.data.error));
          reject(response);
        });
    });
}

export function updateUserPrivacy(item, setting) {
  return (dispatch, getState) => {
    console.log('updating user privacy');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserPrivacy',
        data: {
          item,
          setting,
        },
      })
        .then((response) => {
          console.log('User update responded successfully');
          console.log(response);
          const { data } = response;

          dispatch(userUpdate(data.user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('user update responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateUserPictureSettings(setting) {
  return (dispatch, getState) => {
    console.log('updating user picture setting');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/updateUserPictureSettings',
        data: {
          setting,
        },
      })
        .then((response) => {
          // console.log('User update responded successfully');
          // console.log(response);
          const { data } = response;

          dispatch(userUpdate(data.user));

          resolve(data.point);
        })
        .catch((response) => {
          console.log('user update responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function updateUserReadingSettings(settings) {
  return (dispatch) =>
    axios
      .post('/api/users/updateReadingSettings', settings)
      .then((resp) => {
        dispatch(userUpdate(resp.data.user));
      })
      .catch((err) => {
        throw err.response.data;
      });
}

export function updateUserCommunicationsSettings(settings) {
  return (dispatch) =>
    axios
      .post('/api/users/updateUserCommunicationSettings', settings)
      .then((resp) => {
        dispatch(userUpdate(resp.data.user));
      })
      .catch((err) => {
        console.log('Failed to update user communication settings', err);
      });
}

export function updateUserEmailSettings(settings) {
  return (dispatch) =>
    axios
      .post('/api/users/updateEmailSettings', settings)
      .then((resp) => {
        dispatch(userUpdate(resp.data.user));
      })
      .catch((err) => {
        throw err.response.data;
      });
}

export function updateNotificationSettings(settings) {
  return (dispatch) =>
    axios
      .post('/api/users/updateNotificationSettings', settings)
      .then((resp) => {
        dispatch(userUpdate(resp.data.user));
      })
      .catch((err) => {
        throw err.response.data;
      });
}

export function updateNotificationPreferences(preferences) {
  return (dispatch) =>
    axios
      .post('/api/users/updateNotificationPreferences', preferences)
      .then((resp) => {
        dispatch(userUpdate(resp.data.user));
      })
      .catch((err) => {
        throw err.response.data;
      });
}

export function requestForUserProfile(userId, forField, note) {
  return (dispatch, getState) => {
    console.log(`requesting info for the User ${userId}`);
    console.log(`requesting info forField ${forField}`);

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/users/requestFor',
        data: {
          request: {
            subjectId: userId,
            requestFor: forField,
            note,
          },
        },
      })
        .then((response) => {
          console.log('RequestFor responded with success');
          console.log(response);
          const user = response.data;
          // TBD - need to fix - update portfolio
          // dispatch(userUpdate(user));
          dispatch({ type: UPDATE_ANOTHER_USER, user });

          toast(`Your request for this user's ${forField} was submitted`);
          resolve(user);
        })
        .catch((response) => {
          console.log('RequestFor responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function submitCustomerContact(data) {
  const { message, contactMethod, email, phone, loggedOutName } = data;

  return (dispatch) =>
    //dispatch(beginRegister())
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/averPointCustomerContacts/submitMessage',
        data: {
          message,
          contactMethod,
          email,
          phone,
          loggedOutName,
        },
      })
        .then((response) => {
          if (response.data.success) {
            resolve();
          } else {
            reject(response.data.message);
          }
        })
        .catch((response) => {
          if (response instanceof Error) {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', response.message);
          }
        });
    });
}

export function emailSignUp(data) {
  return (dispatch) =>
    //dispatch(beginRegister())
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/auth/emailSignUp',
        data,
      })
        .then((response) => {
          if (data.tempData.emailSignUpFlow === 'follow') {
            toast('Followed successfully.');
          }
          resolve(response);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
}

export function clubhouseSignup(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/auth/clubhouseSignup',
        data,
      })
        .then((response) => {
          toast(`Sign up link sent to ${data.email}`);
          resolve(response);
        })
        .catch((err) => {
          console.log('Error while sign up', err);
          reject(err);
        });
    });
}

export function schoolRegistration(data) {
  return () => {
    return axios.post('/api/auth/schoolRegistration', data);
  };
}

export function classRoomRegistration(data) {
  return () => {
    return axios.post('/api/auth/classRoomRegistration', data);
  };
}

export function partnerRegistration(data) {
  return () => {
    return axios.post('/api/auth/partnerRegistration', data);
  };
}

export function subscribeNewsletter(data) {
  return () => {
    return axios.post('/api/auth/subscribeNewsletter', data);
  };
}

export function getAllCommunityUsers() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/users/getAllCommunityUsers',
      })
        .then((response) => {
          console.log('response ON actions', response);
          let data = response.data;
          dispatch(allCommunityUserList(data));
        })
        .catch((response) => {
          console.log('getAll Users responded error');
          console.log(response);
        });
    });
}

export function logSignupEvent(data) {
  return () =>
    new Promise((resolve) => {
      axios({
        method: 'post',
        url: '/api/auth/logSignupEvent',
        data,
      })
        .then((response) => {
          console.log('Logged sign up event', response);
          resolve();
        })
        .catch((err) => {
          console.log('Error while logging sign up event', err);
          resolve();
        });
    });
}

export function resendVerificationCode(data) {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/resendVerificationCode', data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
}

export function activateStudentRole() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/activateStudentRole')
        .then((response) => {
          dispatch(activateStudent());

          const userObject = getState().user;
          const user = userObject ? userObject.user : null;

          if (user && user.username) {
            dispatch({
              type: Actions.UPDATE_STUDENT_ROLE_PORTFOLIO,
              username: user.username,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while activating student role', error);
          resolve();
        });
    });
}

export function activateTeacherRole() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/activateTeacherRole')
        .then((response) => {
          dispatch(activateTeacher());

          const userObject = getState().user;
          const user = userObject ? userObject.user : null;

          if (user && user.username) {
            dispatch({
              type: Actions.UPDATE_TEACHER_ROLE_PORTFOLIO,
              username: user.username,
            });
          }
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while activating teacher role', error);
          resolve();
        });
    });
}

export function getUnverifiedUsers() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getUnverifiedUsers')
        .then((response) => {
          dispatch(loadUnverifiedUsers(response.data.unverifiedUsersByRole));
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while getting unverified users', error);
          resolve();
        });
    });
}

export function verifyUserRole(userId, role) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/users/verifyRole', {
          userId,
          role,
        })
        .then((response) => {
          dispatch(removeUnverifiedUsers(userId, role));
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while verifying users', error);
          resolve();
        });
    });
}

export function assignDefaultLMSApp(lmsApp) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/users/assignDefaultLMSApp', {
          lmsApp,
        })
        .then((response) => {
          dispatch(updateDefaultLMSApp(lmsApp));
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while assigning default lms app', error);
          toast(
            'Error while assigning default lms app. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve();
        });
    });
}

export function getUserLevelHistory(username) {
  return () => {
    return axios.get('/api/users/getUserLevelHistory', {
      params: { username },
    });
  };
}

export function updateUserLevel(username, previousUserLevel, userLevel) {
  return () => {
    return axios.post('/api/users/updateUserLevel', {
      username,
      previousUserLevel,
      userLevel,
    });
  };
}

export function getAdminUsers(queryString, filters, skip, limit) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getAdminUsers', {
          params: { queryString, ...filters, skip, limit },
        })
        .then((response) => {
          resolve(response.data.users);
        })
        .catch((error) => {
          console.error('Error while getting users', error);
          toast('Error while getting users. Please try again letter', {
            type: 'error',
          });
          reject();
        });
    });
}

export function getAdminCourses() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getAdminCourses', {})
        .then((response) => {
          resolve(response.data.courses);
        })
        .catch((error) => {
          console.error('Error while getting courses', error);
          toast('Error while getting courses. Please try again letter', {
            type: 'error',
          });
          resolve([]);
        });
    });
}

export function getStudentsByCourse(courseId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/courses/getStudentsByCourse', { params: { courseId } })
        .then((response) => {
          resolve(response.data.studentsInCourse);
        })
        .catch((error) => {
          console.error('Error while getting students in course', error);
          toast(
            'Error while getting students in course. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve([]);
        });
    });
}

export function getUsersByCourse(courseId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/courses/getUsersByCourse', { params: { courseId } })
        .then((response) => {
          resolve(response.data.usersInCourses);
        })
        .catch((error) => {
          console.error('Error while getting users in course', error);
          toast(
            'Error while getting users in course. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve([]);
        });
    });
}

export function getUserExtensionStatus(username) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getUserExtensionStatus', {
          params: { username },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting users extension status', error);
          toast(
            'Error while getting users extension status. Please try again letter',
            {
              type: 'error',
            },
          );
          reject();
        });
    });
}

export function updateUserRoles(userId, roles, schools, subscriptions) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/users/updateUserRoles', {
          userId,
          roles,
          schools,
          subscriptions,
        })
        .then((response) => {
          toast('User have been updated', {
            type: 'success',
          });
          resolve();
        })
        .catch((error) => {
          console.error('Error while updating user roles', error);
          toast('Error while updating user roles. Please try again letter', {
            type: 'error',
          });
          resolve();
        });
    });
}

export function uninstallExtension(data) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/uninstallExtension', data)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          resolve();
        });
    });
}

export function getAdminDashboardStats() {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getAdminDashboardStats')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting admin dashboard stats', error);
          toast(
            'Error while getting admin dashboard stats. Please try again letter',
            {
              type: 'error',
            },
          );
          reject();
        });
    });
}

export function getCourseList(params) {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getCourseList', { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting course list', error);
          toast('Error while getting course list. Please try again letter', {
            type: 'error',
          });
          reject();
        });
    });
}

export function verifyUserSchool(req) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/users/verifyUserSchool', req)
        .then((response) => {
          toast('User school verified', {
            type: 'success',
          });
          resolve();
        })
        .catch((error) => {
          console.error('Error while verifying user school', error);
          toast('Error while verifying user school. Please try again letter', {
            type: 'error',
          });
          resolve();
        });
    });
}

export function getUnverifiedParents(courseId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/parent/getUnverifiedParents')
        .then((response) => {
          resolve(response.data.parents);
        })
        .catch((error) => {
          console.error('Error while getting unverified parents', error);
          toast(
            'Error while getting unverified parents. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve([]);
        });
    });
}

export function getUnverifiedUsersList(searchTerm, schoolId, limit, page) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getUnverifiedUsersList', {
          params: { searchTerm, schoolId, limit, page },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting unverified users', error);
          toast(
            'Error while getting unverified users. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve([]);
        });
    });
}

export function generateUserMediaLogReport(searchTerm, limit, page) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/generateUserMediaLogReport', {
          params: { searchTerm, limit, page },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting users report', error);
          toast('Error while getting users report. Please try again letter', {
            type: 'error',
          });
          reject(error);
        });
    });
}

export function sendManualVerificationCode(email) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/sendManualVerificationCode', { email })
        .then((response) => {
          resolve(response.data);
          toast('Verification code sent', {
            type: 'success',
          });
        })
        .catch((error) => {
          console.error('Error while sending verification code', error);
          toast(
            'Error while sending verification code. Please try again letter',
            {
              type: 'error',
            },
          );
          reject(error);
        });
    });
}

export function verifyManualVerificationCode(
  email,
  verificationCode,
  forceVerify,
) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .post('/api/auth/verifyManualVerificationCode', {
          email,
          verificationCode,
          forceVerify,
        })
        .then((response) => {
          toast('User has been verified', {
            type: 'success',
          });
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while verifying unverified user', error);
          toast(`Error: ${error.message}`, {
            type: 'error',
          });
          reject(error);
        });
    });
}

export function approveParentOfStudent(childId, parentEmail) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/parent/approveParentOfStudent', {
          params: { childId, parentEmail },
        })
        .then((response) => {
          toast('Successfully invited parents of the student.', {
            type: 'success',
          });
          resolve();
        })
        .catch((error) => {
          console.error('Error while approving parents', error);
          toast('Error while approving parents', {
            type: 'error',
          });
          resolve([]);
        });
    });
}

export function allUsersReadingsIntervals(filters) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/allUsersReadingsIntervals', { params: filters })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting readings intervals', error);
          toast(
            'Error while getting readings intervals. Please try again letter',
            {
              type: 'error',
            },
          );
          resolve([]);
        });
    });
}

export function getSchoolsForUsers(emails) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/getSchoolsForUsers', { params: { emails } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while getting schools', error);
          reject();
        });
    });
}

export function syncSchoolUsersWithEmailHandle(params) {
  return () =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/syncSchoolUsersWithEmailHandle', { params })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while syncing users with school', error);
          toast(
            'Error while syncing users with school. Please try again letter',
            {
              type: 'error',
            },
          );
          reject();
        });
    });
}

export function activateVocabularyForUser() {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/activateVocabulary')
        .then((response) => {
          dispatch(activateVocabulary());
          resolve(response);
        })
        .catch((error) => {
          console.error('Error while activating vocabulary', error);
          toast(
            'Error while activating vocabulary. Please try again or contact admin to activate.',
            {
              type: 'error',
            },
          );
          resolve();
        });
    });
}

export function downloadCourseUsers(courseId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/downloadCourseUsers', { params: { courseId } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while downloading course users', error);
          toast('Error while downloading course users', {
            type: 'error',
          });
          reject(error);
        });
    });
}

export function permanentDeleteUser(userId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/permanentDeleteUser', { params: { userId } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while permanently deleting user', error);
          toast(
            'Error while deleting user. Please contact support to delete the user.',
            {
              type: 'error',
            },
          );
          reject(error);
        });
    });
}

export function anonymizeUserData(userId) {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      axios
        .get('/api/users/anonymizeUserData', { params: { userId } })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('Error while anonymizing user', error);
          toast('Error while anonymizing user', {
            type: 'error',
          });
          reject(error);
        });
    });
}
