import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';
import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageTopic.scss';

function ManageTopic({ courseId, topic = {}, courses, onClose, actions }) {
  const [name, setName] = useState(topic.name || '');
  const course = courses.find((course) => course.courseId === courseId);
  const [dueDate, setDueDate] = useState(
    () => topic.dueDate || course.endDate || moment().format('YYYY-MM-DD'),
  );
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [name, dueDate]);

  const createTopic = () => {
    if (topic._id) {
      actions
        .updateTopic(courseId, topic._id, name, dueDate)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while updating topic. Please try again.',
          );
        });
    } else {
      actions
        .createTopic(courseId, name, dueDate)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while adding topic. Please try again.',
          );
        });
    }
  };

  return (
    <Modal show={true} className="manage-topic-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>{topic._id ? 'Update' : 'Create'} Topic</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingInput
          autoFocus={true}
          value={name}
          labelName="Topic Name"
          onChange={(e) => setName(e.target.value)}
          error={error || ''}
        />
        <div className="mb-5">
          <FloatingInput
            value={dueDate ? moment(dueDate).format('YYYY-MM-DD') : ' '}
            type="date"
            labelName="Due Date"
            onChange={(e) => setDueDate(e.target.value)}
            min={moment().format('YYYY-MM-DD')}
            onFocus={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
            onClick={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
          />
        </div>
        <RectangularButton
          className="next-button"
          disabled={!name}
          onClick={() => createTopic()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    courses: state.classRoom.courses || [],
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTopic);
