import * as Actions from './../actions/index';

import { toast } from 'components/elements/molecules/Toast/Toast';

const user = (
  state = {
    /*
    configure store onLoad is not getting called if 'averpoint.root' key is not present in 
    local storage. So checking 'averpoint.root' key to set isWaiting flag.
    */
    isWaiting: localStorage.getItem('averpoint.root') !== null,
    authenticated: false,
    user: {},
    notifications: [],
    topUsers: [],
    initializing: false,
    inviteOnURL: null, //- this would allow users with invites to click the sign up link, click other stuff, and then return
    impersonateUsername: null,
    impersonateUserId: null,
  },
  action,
) => {
  switch (action.type) {
    case Actions.NEW_SESSION:
      console.log('new session - no session to restore');
      return { ...state, initializing: false };
    case Actions.SYNC_SESSION:
      console.log('sync session started reducer');
      return { ...state, initializing: true, isWaiting: true };
    case Actions.SYNC_SESSION_SUCCESS:
      console.log('sync session success reducer');
      return {
        ...state,
        initializing: false,
        isWaiting: false,
        authenticated: true,
        user: action.data.user,
        notifications: action.data.notifications,
        searchKey: action.data.searchKey,
      };
    case Actions.SYNC_SESSION_ERROR:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: false,
        initializing: false,
      });
    case Actions.MANUAL_LOGIN_USER:
    case Actions.SOCIAL_LOGIN:
      return Object.assign({}, state, { isWaiting: true });
    case Actions.LOGIN_SUCCESS_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: true,
        user: action.data.user,
        notifications: action.data.notifications,
        searchKey: action.data.searchKey,
      });
    case Actions.LOGIN_USERNAME_REQUIRE:
      return Object.assign({}, state, {
        isUsernameRquire: true,
        authenticated: false,
      });
    case Actions.LOGIN_ERROR_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: false,
      });
    case Actions.SIGNUP_USER:
      return Object.assign({}, state, { isWaiting: true });
    case Actions.SIGNUP_SUCCESS_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: true,
      });
    case Actions.SIGNUP_ERROR_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: false,
      });
    case Actions.LOGOUT_USER:
      return Object.assign({}, state, { isWaiting: true });
    case Actions.LOGOUT_SUCCESS_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: false,
        user: {},
        notifications: [],
        searchKey: null,
      });
    case Actions.LOGOUT_ERROR_USER:
      return Object.assign({}, state, {
        isWaiting: false,
        authenticated: true,
      });
    case Actions.REGISTER_USER:
      return Object.assign({}, state, { isWaiting: true });
    case Actions.REGISTER_SUCCESS_USER:
      return Object.assign({}, state, { isWaiting: false });
    case Actions.REGISTER_ERROR_USER:
      return Object.assign({}, state, { isWaiting: false });
    case Actions.NOTIFICATIONS_UPDATED:
    case Actions.NOTIFICATIONS_SEEN:
      return Object.assign({}, state, { notifications: action.notifications });
    case Actions.NEW_NOTIFICATION: {
      if (action.notification.userId != state.user._id) return state; //This notification was for another user
      var newNotifications = state.notifications.concat(action.notification);
      if (action.notification && action.notification.text) {
        toast(action.notification.text);
      }
      return Object.assign({}, state, { notifications: newNotifications });
    }
    case 'NEW_SCORE': {
      //Action used by pusher to update the score
      if (action.userId != state.user._id) return state; //This notification was for another user

      let newUser = state.user;
      newUser.score = action.newScore;

      return Object.assign({}, state, { user: newUser });
    }
    case Actions.UPDATE_USER: {
      var newUser = state.user;
      newUser.photoLicenseTerms = action.user.photoLicenseTerms;
      newUser.profile = action.user.profile;
      newUser.settings = action.user.settings;
      newUser.notificationPreferences = action.user.notificationPreferences;
      newUser.hostedImageVersion = action.user.hostedImageVersion;
      return Object.assign({}, state, { user: newUser });
    }
    case Actions.GET_ALL_USERS: {
      return Object.assign({}, state, { userList: action.userList });
    }
    case Actions.GET_ALL_COMMUNITY_USERS: {
      return Object.assign({}, state, { communityUserList: action.userList });
    }

    case Actions.TOP_USERS:
      return {
        ...state,
        topUsers: action.users,
      };
    case 'HAS_INVITE':
      return {
        ...state,
        inviteOnURL: action.inviteOnURL,
      };
    case 'LOADING_SESSION':
      return {
        ...state,
        isWaiting: true,
      };
    case Actions.REFRESH_TOKEN: {
      var newUser = state.user;
      newUser.token = action.accessToken;
      return Object.assign({}, state, { user: newUser });
    }
    case Actions.ACTIVATE_STUDENT_ROLE: {
      const newUser = JSON.parse(JSON.stringify(state.user));

      const roles = newUser.profile.roles || [];
      roles.push({ type: 'student', status: 'activated' });
      newUser.profile.roles = roles;

      return Object.assign({}, state, { user: newUser });
    }
    case Actions.ACTIVATE_TEACHER_ROLE: {
      const newUser = JSON.parse(JSON.stringify(state.user));

      const roles = newUser.profile.roles || [];
      roles.push({ type: 'teacher', status: 'activated' });
      newUser.profile.roles = roles;

      return Object.assign({}, state, { user: newUser });
    }
    case Actions.ACTIVATE_VOCABULARY: {
      const newUser = JSON.parse(JSON.stringify(state.user));

      newUser.profile.vocabularyEnabled = true;

      return Object.assign({}, state, { user: newUser });
    }
    case Actions.ADD_GOOGLE_DETAILS: {
      var newUser = JSON.parse(JSON.stringify(state.user));
      newUser.google = action.googleUserObject;
      return Object.assign({}, state, { user: newUser });
    }
    case Actions.LOAD_UNVERIFIED_USERS: {
      const unverifiedUsersByRole = action.unverifiedUsersByRole;
      return Object.assign({}, state, { unverifiedUsersByRole });
    }
    case Actions.REMOVE_UNVERIFIED_USER: {
      let unverifiedUsersByRole = JSON.parse(
        JSON.stringify(state.unverifiedUsersByRole),
      );
      unverifiedUsersByRole = unverifiedUsersByRole.filter(
        (user) => user._id !== action.userId || user.role.type !== action.role,
      );
      return Object.assign({}, state, { unverifiedUsersByRole });
    }
    case Actions.UPDATE_DEFAULT_LMS_APP: {
      const newUser = JSON.parse(JSON.stringify(state.user));

      newUser.profile.lmsApp = action.lmsApp;

      return Object.assign({}, state, { user: newUser });
    }
    case Actions.SET_IMPERSONATE_USER: {
      const impersonateUsername = action.username || null;
      const impersonateUserId = action.userId || null;

      return Object.assign({}, state, {
        impersonateUsername,
        impersonateUserId,
      });
    }
    default:
      return state;
  }
};

export default user;
