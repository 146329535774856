import React from 'react';
import ScrollUpButton from 'react-scroll-up';

import { Icon, ICONS } from 'components/Icons';
import { isMobile } from 'helpers';

import './ScrollButton.scss';

const ScrollButton = () => {
  if (isMobile()) {
    return null;
  }

  return (
    <ScrollUpButton showUnder={500}>
      <Icon
        icon={ICONS.SCROLL}
        className="icon-scroll"
        width={64}
        height={64}
      />
    </ScrollUpButton>
  );
};

export default ScrollButton;
