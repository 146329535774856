import React from "react";
import RoundedButton from "../../../elements/molecules/RoundedButton/RoundedButton";

const Step1 = ({ gotoNextStep }) => (
  <div>
    <p>
      Welcome to your Portfolio - the place that holds all your Collections.
    </p>
    {/* <div className="video-container">
      <iframe
        src="https://www.useloom.com/embed/9fe08aba55ab41c589fff20569d25489"
        frameBorder="0"
        webkitAllowFullScreen="true"
        mozAllowFullScreen="true"
        allowFullScreen="true"
      />
    </div> */}
    <RoundedButton label="Got it" onClick={() => gotoNextStep(2)} />
  </div>
);

export default Step1;
