import axios from 'axios';

export function isEmptyUser(user) {
  return typeof user.user === 'object' && Object.keys(user.user).length === 0;
}

export function getCurrentUserId(user) {
  return user.impersonateUserId || user.user._id;
}

export function getCurrentUserName(user) {
  return user.impersonateUsername || user.user.username;
}

export function isTeacher(course, user) {
  if (!course) {
    return false;
  }

  const teacherUsernames = course.teacherUsernames || [];

  const teacher = !isEmptyUser(user)
    ? teacherUsernames.includes(getCurrentUserName(user))
    : false;

  return teacher;
}

export function isTeacherActivated(user) {
  const isTeacher = user.user.profile.roles.find(
    (i) => i.type === 'teacher' && i.status === 'activated',
  );

  return isTeacher;
}

export function isStudent(course, user) {
  const teacherUsernames = course.teacherUsernames;

  const isTeacher = !isEmptyUser(user)
    ? teacherUsernames.includes(getCurrentUserName(user))
    : false;

  return isTeacher;
}

export function isStudentActivated(user) {
  const isStudent = user.user.profile.roles.find(
    (i) => i.type === 'student' && i.status === 'activated',
  );

  return isStudent;
}

export function handleEvent(event, properties) {
  axios.post('/api/events/handleEvent', {
    event,
    properties,
  });
}

export function showStudentSchool(student) {
  const schoolNames = (student.schools || [])
    .map((school) => school.name)
    .filter((name) => name);

  if (schoolNames.length === 0) {
    return 'Unknown';
  }

  const firstSchool = schoolNames[0];
  const remainingCount = schoolNames.length - 1;
  const tooltip = schoolNames.join(', ');

  return (
    <span title={tooltip}>
      {firstSchool}
      {remainingCount > 0 ? ' + ' + remainingCount : ''}
    </span>
  );
}
