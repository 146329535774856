import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Modal, Button } from 'react-bootstrap';

import FloatingInput from 'components/elements/atoms/FloatingInput/FloatingInput';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';
import * as Actions from '../../../redux/actions/index';
import { Icon, ICONS } from '../../Icons';

import './ManageCourse.scss';

function ManageCourse({ onClose, actions, course = {} }) {
  const [name, setName] = useState(course.name || '');
  const [startDate, setStartDate] = useState(() => course.startDate || '');
  const [endDate, setEndDate] = useState(() => course.endDate || '');
  const [error, setError] = useState('');

  useEffect(() => {
    if (error) {
      setError('');
    }
  }, [name]);

  const createCourse = () => {
    if (!course.name) {
      actions
        .createCourse(name, startDate, endDate)
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while adding course. Please try again.',
          );
        });
    } else {
      actions
        .updateCourse({
          courseId: course.courseId,
          name,
          startDate: startDate || null,
          endDate: endDate || null,
          isFavorite: course.isFavorite,
        })
        .then(() => onClose())
        .catch((error) => {
          setError(
            error.message ||
              'Error occurred while adding course. Please try again.',
          );
        });
    }
  };

  return (
    <Modal show={true} className="manage-course-modal">
      <Modal.Body>
        <div className="header mb-4">
          <span>Create Course</span>
          <Button bsStyle="link" className="btn-close" onClick={onClose}>
            <Icon icon={ICONS.CLOSE} />
          </Button>
        </div>
        <FloatingInput
          autoFocus={true}
          value={name}
          labelName="Course Name"
          onChange={(e) => setName(e.target.value)}
          error={error || ''}
        />
        <div className="mb-5">
          <FloatingInput
            value={startDate ? moment(startDate).format('YYYY-MM-DD') : ' '}
            type="date"
            labelName="Start Date"
            onChange={(e) => setStartDate(e.target.value)}
            min={moment().format('YYYY-MM-DD')}
            max={endDate ? moment(endDate).format('YYYY-MM-DD') : ' '}
            onFocus={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
            onClick={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
          />
        </div>
        <div className="mb-5">
          <FloatingInput
            value={endDate ? moment(endDate).format('YYYY-MM-DD') : ' '}
            type="date"
            labelName="End Date"
            onChange={(e) => setEndDate(e.target.value)}
            min={
              startDate
                ? moment(startDate).format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD')
            }
            onFocus={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
            onClick={(e) => {
              try {
                e.target.showPicker();
              } catch {}
            }}
          />
        </div>
        <RectangularButton
          className="next-button"
          disabled={!name}
          onClick={() => createCourse()}
        >
          Submit
        </RectangularButton>
      </Modal.Body>
    </Modal>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(ManageCourse);
