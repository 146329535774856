import axios from 'axios';

import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_COURSES = 'LOAD_COURSES';
export const SET_LOADING_COURSES = 'SET_LOADING_COURSES';
export const LOAD_COURSE = 'LOAD_COURSE';
export const LOAD_TOPIC = 'LOAD_TOPIC';
export const ADD_COURSES = 'ADD_COURSES';
export const UPDATE_COURSES = 'UPDATE_COURSES';
export const ADD_TOPIC = 'ADD_TOPIC';
export const REMOVE_NEW_TAG_FROM_TOPIC = 'REMOVE_NEW_TAG_FROM_TOPIC';
export const UPDATE_TOPIC = 'UPDATE_TOPIC';
export const REMOVE_INVITED_COURSE = 'REMOVE_INVITED_COURSE';
export const CANCEL_INVITED_COURSE = 'CANCEL_INVITED_COURSE';
export const REMOVE_COURSE_USER = 'REMOVE_COURSE_USER';
export const ADD_INVITED_STUDENTS_COURSE = 'ADD_INVITED_STUDENTS_COURSE';
export const ADD_INVITED_TEACHERS_COURSE = 'ADD_INVITED_TEACHERS_COURSE';
export const LOAD_TOPIC_GOALS = 'LOAD_TOPIC_GOALS';
export const LOAD_ALL_STUDENT_PROGRESS = 'LOAD_ALL_STUDENT_PROGRESS';
export const UPDATE_TOPIC_GOALS = 'UPDATE_TOPIC_GOALS';
export const UPDATE_READING_GOALS = 'UPDATE_READING_GOALS';
export const UPDATE_READING_QUESTIONS = 'UPDATE_READING_QUESTIONS';
export const UPDATE_CHANNEL_ARTICLE_QUESTIONS =
  'UPDATE_CHANNEL_ARTICLE_QUESTIONS';
export const LOAD_QUIZ_QUESTIONS = 'LOAD_QUIZ_QUESTIONS';
export const ADD_READING = 'ADD_READING';
export const CLEAR_ADDED_READINGS = 'CLEAR_ADDED_READINGS';
export const REMOVE_READING = 'REMOVE_READING';
export const UPDATE_READING = 'UPDATE_READING';
export const ADD_PENDING_SOURCE_INFO_READING =
  'ADD_PENDING_SOURCE_INFO_READING';
export const UPDATE_PENDING_SOURCE_INFO_READING =
  'UPDATE_PENDING_SOURCE_INFO_READING';
export const REMOVE_PENDING_SOURCE_INFO_READING =
  'REMOVE_PENDING_SOURCE_INFO_READING';
export const UPDATE_SOURCE_FORMAT_READING = 'UPDATE_SOURCE_FORMAT_READING';
export const ACCEPT_COURSE = 'ACCEPT_COURSE';
export const REMOVE_COURSE = 'REMOVE_COURSE';
export const REMOVE_TOPIC = 'REMOVE_TOPIC';
export const ADD_STUDENT_STATS = 'ADD_STUDENT_STATS';
export const SELECT_CLASSROOM_ENTITIES = 'SELECT_CLASSROOM_ENTITIES';
export const SELECT_COURSE = 'SELECT_COURSE';
export const SELECT_SHOW_COURSE_DASHBOARD = 'SELECT_SHOW_COURSE_DASHBOARD';
export const REMOVE_COURSE_FILTERS = 'REMOVE_COURSE_FILTERS';
export const SET_COURSE_DASHBOARD_REPORT_NAME =
  'SET_COURSE_DASHBOARD_REPORT_NAME';
export const SELECT_SHOW_COURSE_TEACHERS = 'SELECT_SHOW_COURSE_TEACHERS';
export const SELECT_SHOW_COURSE_STUDENTS = 'SELECT_SHOW_COURSE_STUDENTS';
export const SELECT_SHOW_COURSE_TOP_STUDENTS =
  'SELECT_SHOW_COURSE_TOP_STUDENTS';
export const SELECT_TOPIC = 'SELECT_TOPIC';
export const SELECT_STUDENT = 'SELECT_STUDENT';
export const SELECT_GROUP_STUDENT = 'SELECT_GROUP_STUDENT';
export const SELECT_READING = 'SELECT_READING';
export const SELECT_READING_GRADES = 'SELECT_READING_GRADES';
export const SELECT_READING_GRADES_USERS = 'SELECT_READING_GRADES_USERS';
export const SELECT_WRITING_PROMPT = 'SELECT_WRITING_PROMPT';
export const SELECT_RESEARCH_PROMPT = 'SELECT_RESEARCH_PROMPT';
export const SELECT_RESEARCH_SUBMISSION = 'SELECT_RESEARCH_SUBMISSION';
export const RESET_COURSE = 'RESET_COURSE';
export const UPDATE_WRITING_PROMPT_SETTING = 'UPDATE_WRITING_PROMPT_SETTING';
export const UPDATE_RESEARCH_PROMPT_SETTING = 'UPDATE_RESEARCH_PROMPT_SETTING';
export const UPDATE_READING_IN_WILD_SETTING = 'UPDATE_READING_IN_WILD_SETTING';
export const UPDATE_VOCABULARY_SETTING = 'UPDATE_VOCABULARY_SETTING';
export const UPDATE_COURSE_READING_IN_WILD = 'UPDATE_COURSE_READING_IN_WILD';
export const UPDATE_COURSE_READING_IN_COURSE =
  'UPDATE_COURSE_READING_IN_COURSE';
export const UPDATE_COURSE_READING_IN_ALL = 'UPDATE_COURSE_READING_IN_ALL';
export const TOGGLE_CHANNEL_PAGE_QUIZ = 'TOGGLE_CHANNEL_PAGE_QUIZ';
export const LOAD_TOPIC_READINGS = 'LOAD_TOPIC_READINGS';
export const LOAD_TOPIC_WRITING_PROMPTS = 'LOAD_TOPIC_WRITING_PROMPTS';
export const LOAD_TOPIC_RESEARCH_PROMPTS = 'LOAD_TOPIC_RESEARCH_PROMPTS';

export function loadCourses(courses) {
  return {
    type: LOAD_COURSES,
    courses,
  };
}

export function setLoadingCourses() {
  return {
    type: SET_LOADING_COURSES,
  };
}

export function loadCourse(courseId, course) {
  return {
    type: LOAD_COURSE,
    courseId,
    course,
  };
}

export function loadTopic(courseId, topicId, topic) {
  return {
    type: LOAD_TOPIC,
    courseId,
    topicId,
    topic,
  };
}

export function loadQuizQuestions(questions) {
  return {
    type: LOAD_QUIZ_QUESTIONS,
    questions,
  };
}

export function loadTopicReadings(courseId, topicId, topic) {
  return {
    type: LOAD_TOPIC_READINGS,
    courseId,
    courseId,
    topicId,
    topic,
  };
}

export function loadTopicWritingPrompts(courseId, topicId, topic) {
  return {
    type: LOAD_TOPIC_WRITING_PROMPTS,
    courseId,
    topicId,
    topic,
  };
}

export function loadTopicResearchPrompts(courseId, topicId, topic) {
  return {
    type: LOAD_TOPIC_RESEARCH_PROMPTS,
    courseId,
    topicId,
    topic,
  };
}

export function addCourse(course) {
  return {
    type: ADD_COURSES,
    course,
  };
}

export function updateCourseData(course) {
  return {
    type: UPDATE_COURSES,
    course,
  };
}

export function addTopic(courseId, topic) {
  return {
    type: ADD_TOPIC,
    courseId,
    topic,
  };
}

export function removeNewTagFromTopic(courseId, topicId) {
  return {
    type: REMOVE_NEW_TAG_FROM_TOPIC,
    courseId,
    topicId,
  };
}

export function modifyTopic(topicId, topic) {
  return {
    type: UPDATE_TOPIC,
    topicId,
    topic,
  };
}

export function removeInvitedCourse(courseId) {
  return {
    type: REMOVE_INVITED_COURSE,
    courseId,
  };
}

export function cancelInvitedCourse(courseId, email, isTeacher) {
  return {
    type: CANCEL_INVITED_COURSE,
    courseId,
    email,
    isTeacher,
  };
}

export function removeCourseUser(courseId, username, isTeacher) {
  return {
    type: REMOVE_COURSE_USER,
    courseId,
    username,
    isTeacher,
  };
}

export function addInvitedStudentsToCourse(courseId, emails) {
  return {
    type: ADD_INVITED_STUDENTS_COURSE,
    courseId,
    emails,
  };
}

export function addInvitedTeachersToCourse(courseId, emails) {
  return {
    type: ADD_INVITED_TEACHERS_COURSE,
    courseId,
    emails,
  };
}

export function loadTopicGoals(data, courseId, topicId) {
  return {
    type: LOAD_TOPIC_GOALS,
    data,
    courseId,
    topicId,
  };
}

export function loadAllStudentProgress(data, courseId, topicId) {
  return {
    type: LOAD_ALL_STUDENT_PROGRESS,
    data,
    courseId,
    topicId,
  };
}

export function updateTopicGoals(courseId, topicId, goals) {
  return {
    type: UPDATE_TOPIC_GOALS,
    courseId,
    topicId,
    goals,
  };
}

export function updateReadingGoals(courseId, topicId, readingId, goals) {
  return {
    type: UPDATE_READING_GOALS,
    courseId,
    topicId,
    readingId,
    goals,
  };
}

export function addReadingToTopic(courseId, topicId, reading) {
  return {
    type: ADD_READING,
    courseId,
    topicId,
    reading,
  };
}

export function clearAddedReadings(courseId, topicId) {
  return {
    type: CLEAR_ADDED_READINGS,
    courseId,
    topicId,
  };
}

export function removeReadingFromTopic(courseId, topicId, readingId) {
  return {
    type: REMOVE_READING,
    courseId,
    topicId,
    readingId,
  };
}

export function updateReadingToTopic(courseId, topicId, reading) {
  return {
    type: UPDATE_READING,
    courseId,
    topicId,
    reading,
  };
}

export function addPendingSourceInfoReading(data) {
  return {
    type: ADD_PENDING_SOURCE_INFO_READING,
    data,
  };
}

export function updatePendingSourceInfoReading(
  readingId,
  courseId,
  topicId,
  response,
  error,
) {
  return {
    type: UPDATE_PENDING_SOURCE_INFO_READING,
    readingId,
    courseId,
    topicId,
    response,
    error,
  };
}

export function removePendingSourceInfoReading(readingId, courseId, topicId) {
  return {
    type: REMOVE_PENDING_SOURCE_INFO_READING,
    readingId,
    courseId,
    topicId,
  };
}

export function updateReadingQuestions(
  courseId,
  topicId,
  pointId,
  questions,
  deletedQuestions,
) {
  return {
    type: UPDATE_READING_QUESTIONS,
    courseId,
    topicId,
    pointId,
    questions,
    deletedQuestions,
  };
}

export function updateChannelArticleQuestions(pointId, quizQuestions) {
  return {
    type: UPDATE_CHANNEL_ARTICLE_QUESTIONS,
    pointId,
    quizQuestions,
  };
}

export function acceptCourseInvitation(courseId) {
  return {
    type: ACCEPT_COURSE,
    courseId,
  };
}

export function removeCourse(courseId) {
  return {
    type: REMOVE_COURSE,
    courseId,
  };
}

export function removeTopic(courseId, topicId) {
  return {
    type: REMOVE_TOPIC,
    courseId,
    topicId,
  };
}

export function addStudentStats(courseId, stats) {
  return {
    type: ADD_STUDENT_STATS,
    courseId,
    stats,
  };
}

export function updateSourceFormatReading(
  courseId,
  topicId,
  pointId,
  sourceFormat,
) {
  return {
    type: UPDATE_SOURCE_FORMAT_READING,
    courseId,
    topicId,
    pointId,
    sourceFormat,
  };
}

export function selectClassroomEntity(entities) {
  return {
    type: SELECT_CLASSROOM_ENTITIES,
    entities,
  };
}

export function selectCourse(courseId) {
  return {
    type: SELECT_COURSE,
    courseId,
  };
}

export function selectShowCourseDashboard(
  showCourseDashboard,
  courseDashboardReportName = null,
  courseDashboardFilters = {},
) {
  return {
    type: SELECT_SHOW_COURSE_DASHBOARD,
    showCourseDashboard,
    courseDashboardReportName,
    courseDashboardFilters,
  };
}

export function removeCourseFilters() {
  return {
    type: REMOVE_COURSE_FILTERS,
  };
}

export function setCourseDashboardReportName(courseDashboardReportName) {
  return {
    type: SET_COURSE_DASHBOARD_REPORT_NAME,
    courseDashboardReportName,
  };
}

export function selectShowCourseTeachers(showCourseTeachers) {
  return {
    type: SELECT_SHOW_COURSE_TEACHERS,
    showCourseTeachers,
  };
}

export function selectShowCourseStudents(showCourseStudents) {
  return {
    type: SELECT_SHOW_COURSE_STUDENTS,
    showCourseStudents,
  };
}

export function selectShowCourseTopStudents(showCourseTopStudents) {
  return {
    type: SELECT_SHOW_COURSE_TOP_STUDENTS,
    showCourseTopStudents,
  };
}

export function selectTopic(topicId) {
  return {
    type: SELECT_TOPIC,
    topicId,
  };
}

export function selectStudent(studentId) {
  return {
    type: SELECT_STUDENT,
    studentId,
  };
}

export function selectGroupStudent(groupStudentId) {
  return {
    type: SELECT_GROUP_STUDENT,
    groupStudentId,
  };
}

export function selectReading(readingId) {
  return {
    type: SELECT_READING,
    readingId,
  };
}

export function selectReadingGrades(readingId) {
  return {
    type: SELECT_READING_GRADES,
    readingId,
  };
}

export function selectReadingGradesUsers(users) {
  return {
    type: SELECT_READING_GRADES_USERS,
    users,
  };
}

export function selectWritingPrompt(writingPromptId) {
  return {
    type: SELECT_WRITING_PROMPT,
    writingPromptId,
  };
}

export function selectResearchPrompt(researchPromptId) {
  return {
    type: SELECT_RESEARCH_PROMPT,
    researchPromptId,
  };
}

export function selectResearchSubmission(
  researchPromptId,
  researchSubmissionId,
) {
  return {
    type: SELECT_RESEARCH_SUBMISSION,
    researchPromptId,
    researchSubmissionId,
  };
}

export function resetCourses() {
  return {
    type: RESET_COURSE,
  };
}

export function modifyWritingPromptSettings(courseId, topicId, topic) {
  return {
    type: UPDATE_WRITING_PROMPT_SETTING,
    courseId,
    topicId,
    topic,
  };
}

export function modifyResearchPromptSettings(courseId, topicId, topic) {
  return {
    type: UPDATE_RESEARCH_PROMPT_SETTING,
    courseId,
    topicId,
    topic,
  };
}

export function modifyReadingsInWildSetting(courseId, readingsInWild) {
  return {
    type: UPDATE_READING_IN_WILD_SETTING,
    courseId,
    readingsInWild,
  };
}

export function modifyVocabularySetting(courseId, vocabularyEnabled) {
  return {
    type: UPDATE_VOCABULARY_SETTING,
    courseId,
    vocabularyEnabled,
  };
}

export function updateCourseReadingInWild(courseId, data) {
  return {
    type: UPDATE_COURSE_READING_IN_WILD,
    courseId,
    data,
  };
}

export function updateCourseReadingInCourse(courseId, data) {
  return {
    type: UPDATE_COURSE_READING_IN_COURSE,
    courseId,
    data,
  };
}

export function updateCourseReadingInAll(courseId, data) {
  return {
    type: UPDATE_COURSE_READING_IN_ALL,
    courseId,
    data,
  };
}

export function toggleChannelPageQuiz(showChannelPageQuiz) {
  return {
    type: TOGGLE_CHANNEL_PAGE_QUIZ,
    showChannelPageQuiz,
  };
}

export function getUserCourses() {
  return (dispatch) => {
    dispatch(setLoadingCourses());
    return new Promise((resolve) => {
      axios
        .get('/api/courses/getUserCourses')
        .then((response) => {
          const data = response.data;
          dispatch(loadCourses(data.courses));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while fetching courses', error);
          toast('Error occurred while fetching courses. Please try again.', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getUserCourse(courseId, redirectUserToCourses) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/getUserCourseDetails', {
          params: { courseId },
        })
        .then((response) => {
          const course = response.data.course;
          dispatch(loadCourse(courseId, course));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while fetching course', error);
          toast(
            'Error occurred while fetching course details. Please try again.',
            {
              type: 'error',
            },
          );
          if (redirectUserToCourses) {
            redirectUserToCourses();
          }
          return resolve();
        });
    });
  };
}

export function getUserCourseTopicDetails(courseId, topicId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/getCourseTopicDetails', {
          params: { courseId, topicId },
        })
        .then((response) => {
          const topic = response.data.topic;
          dispatch(loadTopic(courseId, topicId, topic));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while fetching course topic', error);
          toast(
            'Error occurred while fetching course topic details. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getTopicQuizQuestions(courseId, topicId) {
  return (dispatch) => {
    return axios
      .get('/api/courses/getTopicQuizQuestions', {
        params: { courseId, topicId },
      })
      .then((res) => {
        const data = res.data;
        dispatch(loadQuizQuestions(data.quizQuestions));
        return data;
      })
      .catch((error) => {
        console.log('Error while fetching topic quiz questions', error);
        toast(
          'Error occurred while fetching topic quiz questions. Please try again.',
          {
            type: 'error',
          },
        );
        throw error;
      });
  };
}

export function getTopicGoals(topicId, courseId, isTeacher, isRefreshed) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get(
          isTeacher
            ? '/api/courses/getAllStudentsProgress'
            : '/api/courses/getTopicGoals',
          {
            params: { topicId, isTeacher, isRefreshed },
          },
        )
        .then((response) => {
          const data = response.data;
          dispatch(
            isTeacher
              ? loadAllStudentProgress(data, courseId, topicId)
              : loadTopicGoals(data, courseId, topicId),
          );
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while fetching topic goals', error);
          toast(
            'Error occurred while fetching topic goals. Please try again.',
            {
              type: 'error',
            },
          );
          resolve();
        });
    });
  };
}

export function getTopicReadings(courseId, topicId) {
  return (dispatch) => {
    return axios
      .get('/api/courses/getTopicReadings', {
        params: { topicId, courseId },
      })
      .then((res) => {
        const data = res.data;
        const topic = data.topic;
        dispatch(loadTopicReadings(courseId, topicId, topic));
        return data;
      });
  };
}

export function getTopicWritingPrompts(courseId, topicId) {
  return (dispatch) => {
    return axios
      .get('/api/courses/getTopicWritingPrompts', {
        params: { topicId, courseId },
      })
      .then((res) => {
        const data = res.data;
        const topic = data.topic;
        dispatch(loadTopicWritingPrompts(courseId, topicId, topic));
        return data;
      });
  };
}

export function getTopicResearchPrompts(courseId, topicId) {
  return (dispatch) => {
    return axios
      .get('/api/courses/getTopicResearchPrompts', {
        params: { topicId, courseId },
      })
      .then((res) => {
        const data = res.data;
        const topic = data.topic;
        dispatch(loadTopicResearchPrompts(courseId, topicId, topic));
        return data;
      });
  };
}

export function acceptCourse(courseId, isTeacher) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/acceptCourseInvitation', {
          params: {
            courseId,
            isTeacher,
          },
        })
        .then(() => {
          dispatch(acceptCourseInvitation(courseId));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while accepting courses', error);
          toast(
            'Error occurred while accepting course invitation. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function cancelCourseInvitation(courseId, email, isTeacher) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/cancelCourseInvitation', {
          params: {
            courseId,
            email,
            isTeacher,
          },
        })
        .then((response) => {
          dispatch(cancelInvitedCourse(courseId, email, isTeacher));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while canceling course invitation', error);
          toast(
            'Error occurred while canceling course invitation. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({ success: false });
        });
    });
  };
}

export function deleteCourseUser(courseId, username, isTeacher) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/removeCourseUser', {
          params: {
            courseId,
            username,
            isTeacher,
          },
        })
        .then((response) => {
          dispatch(removeCourseUser(courseId, username, isTeacher));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while removing user from course', error);
          toast(
            'Error occurred while removing user from course. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({ success: false });
        });
    });
  };
}

export function rejectCourse(courseId, isTeacher) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/rejectCourseInvitation', {
          params: {
            courseId,
            isTeacher,
          },
        })
        .then(() => {
          dispatch(removeInvitedCourse(courseId));
          return resolve();
        })
        .catch((error) => {
          console.log('Error while rejecting courses', error);
          toast(
            'Error occurred while rejecting course invitation. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function inviteStudents(courseId, students, newSchools) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let lowerCaseEmails = students.map((student) => ({
        ...student,
        email: student.email.toLowerCase(),
      }));

      axios
        .post('/api/courses/inviteStudents', {
          courseId,
          emails: lowerCaseEmails,
          newSchools,
        })
        .then((response) => {
          dispatch(addInvitedStudentsToCourse(courseId, lowerCaseEmails));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while inviting students to course', error);
          toast(
            'Error occurred while inviting students to course. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function inviteTeachers(courseId, teachers, newSchools) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let lowerCaseEmails = teachers.map((teacher) => ({
        ...teacher,
        email: teacher.email.toLowerCase(),
      }));

      axios
        .post('/api/courses/inviteTeachers', {
          courseId,
          emails: lowerCaseEmails,
          newSchools,
        })
        .then((response) => {
          dispatch(addInvitedTeachersToCourse(courseId, lowerCaseEmails));
          return resolve(response.data);
        })
        .catch((error) => {
          console.log('Error while inviting students to course', error);
          toast(
            'Error occurred while inviting students to course. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function createCourse(name, startDate, endDate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/createCourse', {
          name,
          startDate,
          endDate,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addCourse(data.course));
          toast('Course has been created successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding course', error);
          toast('Error occurred while adding course. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function updateCourse(course, startDate, endDate, isFavorite) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateCourse', {
          course,
          startDate,
          endDate,
          isFavorite,
        })
        .then((response) => {
          const data = response.data;
          if (data.course) {
            dispatch(updateCourseData(data.course));
          }
          toast('Course has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating course', error);
          toast('Error occurred while updating course. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function createTopic(courseId, name, dueDate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/createTopic', {
          courseId,
          name,
          dueDate,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addTopic(courseId, data.topic));
          toast('Topic has been created successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding topic', error);
          toast('Error occurred while adding topic. Please try again.', {
            type: 'error',
          });
          return reject();
        });
    });
  };
}

export function updateTopic(courseId, topicId, name, dueDate) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateTopic', {
          courseId,
          topicId,
          name,
          dueDate,
        })
        .then((response) => {
          const data = response.data;
          dispatch(modifyTopic(topicId, data.topic));
          toast('Topic has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating topic', error);
          toast('Error occurred while updating topic. Please try again.', {
            type: 'error',
          });
          return reject();
        });
    });
  };
}

export function createGoals(topicId, courseId, goals) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/createGoals', {
          topicId,
          goals,
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateTopicGoals(courseId, topicId, data.goals));
          toast('Goals have been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding goals', error);
          toast('Error occurred while adding goals. Please try again.', {
            type: 'error',
          });
          return reject();
        });
    });
  };
}

export function addReading(
  topicId,
  courseId,
  URL,
  _id,
  readingFormat,
  text,
  sourceTitle,
  authors,
  date,
  sourceName,
  isReference,
  s3PdfFilename,
  s3HtmlFilename,
  sourceFormat,
  updatedSourceName,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/addReading', {
          topicId,
          URL,
          _id,
          readingFormat,
          text,
          sourceTitle,
          authors,
          date,
          sourceName,
          isReference,
          s3PdfFilename,
          s3HtmlFilename,
          sourceFormat,
          updatedSourceName,
        })
        .then((response) => {
          const data = response.data;
          dispatch(addReadingToTopic(courseId, topicId, data.reading));
          toast('Reading has been added successfully', {
            type: 'success',
          });

          if (data.questions && data.reading.pointId) {
            dispatch(
              updateReadingQuestions(
                courseId,
                topicId,
                data.reading.pointId,
                data.questions,
                [],
              ),
            );
          }

          return resolve({
            ...data.reading,
            sourceApprovalStatus: data.sourceApprovalStatus,
          });
        })
        .catch((error) => {
          console.log('Error occurred while adding readings', error);
          toast('Error occurred while adding readings. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function updateReading(
  readingId,
  articleMediaCollectionId,
  topicId,
  courseId,
  text,
  sourceTitle,
  sourceAuthor,
  date,
  sourceName,
  sourceFormat,
  updatedSourceName,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateReading', {
          readingId,
          articleMediaCollectionId,
          topicId,
          text,
          sourceTitle,
          sourceAuthor,
          date,
          sourceName,
          sourceFormat,
          updatedSourceName,
        })
        .then((response) => {
          const data = response.data;
          dispatch(updateReadingToTopic(courseId, topicId, data.reading));
          toast('Reading has been updated successfully', {
            type: 'success',
          });
          return resolve({ ...data.reading });
        })
        .catch((error) => {
          console.log('Error occurred while updating reading', error);
          toast('Error occurred while updating reading. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function parsePdfReadingFile(readingId, courseId, topicId, data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/topicReadings/parsePdfReadingFile', data)
        .then((response) => {
          dispatch(
            updatePendingSourceInfoReading(
              readingId,
              courseId,
              topicId,
              response.data,
            ),
          );
          return resolve(response.data);
        })
        .catch((error) => {
          dispatch(
            updatePendingSourceInfoReading(
              readingId,
              courseId,
              topicId,
              null,
              true,
            ),
          );
          toast(
            'Failed to parse pdf file. Please check the file and try again.',
            {
              type: 'error',
            },
          );
          return reject(error);
        });
    });
  };
}

export function createReadingGoals(topicId, courseId, readingId, goals) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/createReadingGoals', {
          topicId,
          readingId,
          goals,
        })
        .then((response) => {
          dispatch(updateReadingGoals(courseId, topicId, readingId, goals));
          toast('Reading goals have been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding reading goals', error);
          toast(
            'Error occurred while adding reading goals. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function addReadingsQuestions(
  topicId,
  courseId,
  pointId,
  questions,
  deletedQuestions,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/addReadingsQuestions', {
          topicId,
          pointId,
          questions,
          deletedQuestions,
        })
        .then((response) => {
          const data = response.data;
          dispatch(
            updateReadingQuestions(
              courseId,
              topicId,
              pointId,
              data.questions,
              deletedQuestions,
            ),
          );
          toast('Reading quiz has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while adding reading goals', error);
          toast(
            'Error occurred while adding reading goals. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function addPointQuestions(pointId, questions, deletedQuestions) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quiz/addPointQuestions', {
          pointId,
          questions,
          deletedQuestions,
        })
        .then((response) => {
          const data = response.data;
          toast('Article quiz has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating article quiz', error);
          toast(
            'Error occurred while updating article quiz. Please try again.',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function getQuizQuestionsForArticle(
  articleMediaCollectionId,
  courseId,
  topicId,
  doNotGenerateQuestions = false,
  isChannelPage = false,
) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post('/api/quiz/getQuizQuestionsForArticle', {
          articleMediaCollectionId,
          courseId,
          topicId,
          doNotGenerateQuestions,
        })
        .then((response) => {
          const data = response.data;
          if (!isChannelPage) {
            dispatch(
              updateReadingQuestions(
                courseId,
                topicId,
                articleMediaCollectionId,
                data.questions,
                [],
              ),
            );
          } else {
            dispatch(
              updateChannelArticleQuestions(
                articleMediaCollectionId,
                data.questions,
              ),
            );
          }
          return resolve(data);
        })
        .catch((error) => {
          resolve();
        });
    });
  };
}

export function submitQuiz(articleMediaCollectionId, topicId) {
  return () => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quiz/submitQuiz', {
          articleMediaCollectionId,
          topicId,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while submitting quiz', error);
          return reject(error);
        });
    });
  };
}

export function getTopicReadingText(articleMediaCollectionId, readingId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/getTopicReadingText', {
          params: { articleMediaCollectionId, readingId },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          resolve();
        });
    });
  };
}

export function getStudentsProgressByReading(topicId, pointId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/getStudentsProgressByReading', {
          params: { topicId, pointId },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          resolve();
        });
    });
  };
}

export function deleteCourse(courseId, forceDelete = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/courses/deleteCourse', {
          params: { courseId, forceDelete },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(removeCourse(courseId));
            toast('Course has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting course', error);
          toast('Error occurred while deleting course. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function deleteTopic(courseId, topicId, forceDelete = false) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/courses/deleteTopic', {
          params: { topicId, forceDelete },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(removeTopic(courseId, topicId));
            toast('Topic has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting topic', error);
          toast('Error occurred while deleting topic. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function deleteReading(
  courseId,
  topicId,
  readingId,
  forceDelete = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .delete('/api/courses/deleteReading', {
          params: { topicId, readingId, forceDelete },
        })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            dispatch(removeReadingFromTopic(courseId, topicId, readingId));
            toast('Reading has been deleted successfully', {
              type: 'success',
            });
          }
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while deleting Reading', error);
          toast('Error occurred while deleting reading. Please try again.', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function courseStudentStats(courseId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/courseStudentStats', {
          params: { courseId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(addStudentStats(courseId, data));
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while fetching course stats', error);
          toast(
            'Error occurred while fetching course stats. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({});
        });
    });
  };
}

export function compareCourseChanges(app) {
  return () => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/compareCourseChanges', {
          params: { app },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while syncing courses', error);
          return reject(error);
        });
    });
  };
}

export function courseStudentReadingStats(courseId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/courseStudentReadingStats', {
          params: { courseId },
        })
        .then((response) => {
          const data = response.data;
          dispatch(addStudentStats(courseId, data));
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while fetching course stats', error);
          toast(
            'Error occurred while fetching course stats. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({});
        });
    });
  };
}

export function syncCoursesToAverPoint(addedCourses, updatedCourses, app) {
  return () => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/syncCoursesToAverPoint', {
          addedCourses,
          updatedCourses,
          app,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while syncing courses', error);
          return reject(error);
        });
    });
  };
}

export function syncCoursesToExternalApp(unlinkedCourses, app) {
  return () => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/syncCoursesToExternalApp', {
          unlinkedCourses,
          app,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while syncing courses', error);
          return reject(error);
        });
    });
  };
}

export function updateSourceFormatForReading(
  courseId,
  topicId,
  pointId,
  sourceFormat,
  existingSourceFormat,
) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/topicReadings/updateSourceFormatForReading', {
          params: { pointId, sourceFormat, existingSourceFormat },
        })
        .then((response) => {
          const data = response.data;
          dispatch(
            updateSourceFormatReading(courseId, topicId, pointId, sourceFormat),
          );
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while fetching source format', error);
          toast(
            'Error occurred while updating source format. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({});
        });
    });
  };
}

export function updateWritingPromptSettings(
  courseId,
  topicId,
  writingPromptSettings,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateWritingPromptSettings', {
          courseId,
          topicId,
          writingPromptSettings,
        })
        .then((response) => {
          const data = response.data;
          dispatch(modifyWritingPromptSettings(courseId, topicId, data.topic));
          toast('Writing prompt setting has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating topic', error);
          toast(
            'Error occurred while updating writing prompt setting. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function updateResearchPromptSettings(
  courseId,
  topicId,
  researchPromptSettings,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateResearchPromptSettings', {
          courseId,
          topicId,
          researchPromptSettings,
        })
        .then((response) => {
          const data = response.data;
          dispatch(modifyResearchPromptSettings(courseId, topicId, data.topic));
          toast('Research prompt setting has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while updating topic', error);
          toast(
            'Error occurred while updating research prompt setting. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function updateReadingsInWildSetting(courseId, readingsInWild) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateReadingsInWildSetting', {
          courseId,
          readingsInWild,
        })
        .then((response) => {
          dispatch(modifyReadingsInWildSetting(courseId, readingsInWild));
          toast('Readings in the wild setting has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log(
            'Error occurred while updating readings in the wild setting',
            error,
          );
          toast(
            'Error occurred while updating readings in the wild setting. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function vocabularyEnabled(courseId, vocabularyEnabled) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateVocabularySetting', {
          courseId,
          vocabularyEnabled,
        })
        .then((response) => {
          dispatch(modifyVocabularySetting(courseId, vocabularyEnabled));
          toast('Vocabulary setting has been updated successfully', {
            type: 'success',
          });
          return resolve();
        })
        .catch((error) => {
          console.log(
            'Error occurred while updating vocabulary setting',
            error,
          );
          toast(
            'Error occurred while updating vocabulary setting. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve();
        });
    });
  };
}

export function getReadingsByStudentInWild(courseId) {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/courses/readingsByStudentInWild', {
          params: { courseId },
        })
        .then((response) => {
          const data = response.data.readingsByStudentInWild;
          dispatch(updateCourseReadingInWild(courseId, data));
          dispatch(
            updateCourseReadingInCourse(
              courseId,
              response.data.readingsByStudentInCourse,
            ),
          );
          dispatch(
            updateCourseReadingInAll(
              courseId,
              response.data.readingsByStudentInAll,
            ),
          );
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while getting readings by student in wild',
            error,
          );
          toast(
            'Error occurred while getting readings by student in wild. Please try again.',
            {
              type: 'error',
            },
          );
          return resolve({});
        });
    });
  };
}

export function getReadingPdfUrl(readingId) {
  return () => {
    return new Promise((resolve) => {
      axios
        .get('/api/topicReadings/readingPdfUrl', {
          params: { readingId },
        })
        .then((res) => {
          return resolve(res.data.url);
        })
        .catch((error) => {
          console.log('Error occurred while getting reading pdf url', error);
          toast(
            'Error occurred while getting reading pdf url. Please try again.',
          );
          return resolve({});
        });
    });
  };
}

export function generateQuestionsFromSource(sourceURL, pointId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quiz/generateQuestionsFromSource', {
          params: { sourceURL, pointId, onlyMCQs: true },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data.questions);
        })
        .catch((error) => {
          console.log('Error occurred while generating questions', error);
          toast(error.error || 'Error occurred while generating questions', {
            type: 'error',
          });
          return reject();
        });
    });
  };
}

export function checkForQuestionAttempt(questionId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quiz/checkForQuestionAttempt', {
          params: { questionId },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while getting answer quiz question',
            error,
          );
          toast(
            error.error || 'Error occurred while getting answer quiz question',
            {
              type: 'error',
            },
          );
          return reject();
        });
    });
  };
}

export function postAnswerQuizQuestion(answer) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quiz/answerQuizQuestion', answer)
        .then((response) => {
          const data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.log('Error occurred while answer quiz question', error);
          toast(error.error || 'Error occurred while answer quiz question', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function raisedDisputeOnQuizQuestion(req) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quiz/raisedDisputeOnQuizQuestion', req)
        .then((response) => {
          const data = response.data;
          resolve(data);
          toast('Feedback submitted successfully', {
            type: 'success',
          });
        })
        .catch((error) => {
          console.log(
            'Error occurred while raising dispute quiz question',
            error,
          );
          toast(error.error || 'raisedDisputeOnQuizQuestion', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getDisputedQuizQuestions() {
  return (dispatch) => {
    return new Promise((resolve) => {
      axios
        .get('/api/quiz/getDisputedQuizQuestions', {})
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while fetching disputed questions',
            error,
          );
          toast('Error occurred while fetching disputed questions', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function updateDisputedQuizQuestionsStatus(disputeId, comment, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quiz/updateDisputedQuizQuestionsStatus', {
          params: { disputeId, comment, status },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while updating disputed questions status',
            error,
          );
          toast('Error occurred while updating disputed questions status', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function getStudentAnswersForArticle(
  courseId,
  topicId,
  pointId,
  getAll = false,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quizGrading/getStudentAnswersForArticle', {
          params: { courseId, topicId, pointId, getAll },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while fetching student answers for article',
            error,
          );
          toast('Error occurred while fetching student answers for article', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function manuallyValidateAnswer(
  courseId,
  answerId,
  teacherComment,
  isCorrect,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quizGrading/manuallyValidateAnswer', {
          courseId,
          answerId,
          teacherComment,
          isCorrect,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while validating student answer for article',
            error,
          );
          toast('Error occurred while validating student answer for article', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function feedbackForAIGrading(
  courseId,
  answerId,
  reasons,
  userReview,
  isCorrect,
) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quizGrading/feedbackForAIGrading', {
          courseId,
          answerId,
          reasons,
          userReview,
          isCorrect,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error occurred while validating student answer for article',
            error,
          );
          toast('Error occurred while validating student answer for article', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function sendStudentReminder(email, signup) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/sendStudentReminder', {
          params: { email, signup },
        })
        .then(() => {
          toast('Reminder sent to the student');
          return resolve();
        })
        .catch((error) => {
          console.log(
            'Error occurred while sending reminder to the student',
            error,
          );
          toast('Error occurred while sending reminder to the student', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function updateReadingOrder(req) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateReadingsOrder', req)
        .then(() => {
          toast('Readings order updated');
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while readings order', error);
          toast('Error occurred while updating readings order', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function updateReadingReference(req) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/courses/updateReadingReference', req)
        .then(() => {
          toast('Readings reference updated');
          return resolve();
        })
        .catch((error) => {
          console.log('Error occurred while readings reference', error);
          toast('Error occurred while updating readings reference', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getArticlesForGradingInWild(courseId, filters) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quizGrading/getArticlesForGradingInWild', {
          params: { courseId, ...filters },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error while fetching articles for grading in wild',
            error,
          );
          toast('Error while fetching articles for grading in wild', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getUserSummaryForArticle(pointId, courseId, filters) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quizGrading/getUserSummaryForArticle', {
          params: { pointId, courseId, ...filters },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log(
            'Error while fetching users summary for an article',
            error,
          );
          toast('Error while fetching users summary for an article', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getSchools(params) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/courses/getSchools', {
          params,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while fetching schools', error);
          toast('Error while fetching schools', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function updateSchool(school) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .put('/api/courses/updateSchool', school)
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while updating schools', error);
          toast('Error while updating schools', {
            type: 'error',
          });
        });
    });
  };
}

export function getQuestionsForArticle(pointId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quizGrading/getQuestionsForArticle', {
          params: { pointId },
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while fetching quiz questions for article', error);
          toast('Error while fetching quiz questions for article', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function useQuestionForTraining(questionId, trainingData) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quizGrading/useQuestionForTraining', {
          questionId,
          trainingData,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while using question for training', error);
          toast('Error while using question for training', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function createQuizQuestionsTrainingDataset(pointId, questionIds) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/quizGrading/createQuizQuestionsTrainingDataset', {
          pointId,
          questionIds,
        })
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while creating question training dataset', error);
          toast('Error while creating question training dataset', {
            type: 'error',
          });
          return reject(error);
        });
    });
  };
}

export function getQuizQuestionsTrainingDatasets() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get('/api/quizGrading/getQuizQuestionsTrainingDatasets')
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while getting training questions', error);
          toast('Error while getting training questions', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}

export function getParticipatingSchools(courseId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/courses/getParticipatingSchools?courseId=${courseId}`)
        .then((response) => {
          const data = response.data;
          return resolve(data);
        })
        .catch((error) => {
          console.log('Error while participating schools', error);
          toast('Error while participating schools', {
            type: 'error',
          });
          return resolve();
        });
    });
  };
}
