/* eslint-disable no-else-return */
/* eslint-disable react/button-has-type */
import React, { useEffect, useReducer, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

function CommentPointForm({
  actions,
  close,
  point,
  user,
  className,
  selectedCaptions,
  ctp,
  rid,
  collectionPoint,
  isMock = false,
  captions,
  courseId,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'COMMENT_POINT_SUBMIT':
          return {
            ...state,
            submitting: true,
          };
        case 'COMMENT_POINT_SUBMIT_SUCCESS':
          return {
            ...state,
            submitting: false,
          };
        case 'SET_TEXT':
          return {
            ...state,
            text: action.payload,
          };
        default:
          return state;
      }
    },
    {
      submitting: false,
      text: '',
    },
  );

  const surveyRef = useRef(null);

  async function onCommentPoint() {
    actions.showHint(
      'info',
      ctp ? 'Updating your comment' : 'Adding your comment to the claim',
    );
    dispatch({ type: 'COMMENT_POINT_SUBMIT' });
    let mediaClaim = point;

    if (!mediaClaim._id) {
      // mediaClaim hasn't been created yet
      point.isMock = isMock;
      const claim = {
        mediaClaimPoint: point,
        articleMediaCollection: collectionPoint,
        context: 'CTP',
      };
      const res = await actions.createMediaClaim(claim);
      mediaClaim = res.mediaClaimPoint;
    }

    if (mediaClaim) {
      const newCTP = {
        pointId: mediaClaim._id,
        userId: user._id,
        isMock,
        articleMediaCollectionId: collectionPoint
          ? collectionPoint._id
          : undefined,
        text: state.text,
      };

      if (ctp) {
        newCTP._id = ctp._id;
      }

      const res = await actions.addCTP(newCTP);
      dispatch({ type: 'COMMENT_POINT_SUBMIT_SUCCESS' });
      close(true, res.ctp);
    } else {
      console.log(
        "We tried everything we could do, but the claim point didn't get created.",
      );
      close(false);
    }

    actions.hideHint();
  }

  function renderClose() {
    return (
      <div className="pl-3 pr-3">
        <div className="av-blue d-flex align-items-center mb-3">
          <button
            className="btn btn-clear btn-close av-blue ml-auto p-0"
            onClick={() => close(false)}
          >
            <Icon icon={ICONS.CLOSE} />
          </button>
        </div>
      </div>
    );
  }

  function renderForm() {
    return (
      <div className="pt-4">
        <textarea
          className="form-control mt-4"
          placeholder="Enter comment"
          value={state.text}
          onChange={(e) =>
            dispatch({ type: 'SET_TEXT', payload: e.target.value })
          }
        />
      </div>
    );
  }

  function renderSaveButton() {
    const disabled = state.submitting || !state.text.trim().length;
    const buttonText = ctp ? 'Update' : 'Submit';

    return (
      <RectangularButton
        className="ml-auto mr-3"
        onClick={onCommentPoint}
        disabled={disabled}
      >
        {buttonText}
        <Icon icon={ICONS.ARROW_FORWARD} className="ml-2" />
      </RectangularButton>
    );
  }

  function renderFooter() {
    return (
      <div
        className="d-flex pt-4 align-items-center mt-4"
        style={{ borderTop: '1px solid #C0CFDA' }}
      >
        {renderSaveButton()}
      </div>
    );
  }

  function renderCTPForm() {
    return (
      <Fragment>
        {renderForm()}
        {renderFooter()}
      </Fragment>
    );
  }

  const containerClassName = classnames(className, 'comment-point-form');

  return (
    <div className={containerClassName}>
      {renderCTPForm()}
      {renderClose()}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentPointForm);
