import React from 'react';

import AppStore from 'assets/images/AppStore.png';
import GooglePlay from 'assets/images/GooglePlay.png';

import './css/Footer.scss';

const REACT_APP_CHROME_EXTENSION_URL =
  process.env.REACT_APP_CHROME_EXTENSION_URL;
const REACT_APP_SAFARI_EXTENSION_URL =
  process.env.REACT_APP_SAFARI_EXTENSION_URL;
const REACT_APP_FIREFOX_EXTENSION_URL =
  process.env.REACT_APP_FIREFOX_EXTENSION_URL;
const REACT_APP_EDGE_EXTENSION_URL = process.env.REACT_APP_EDGE_EXTENSION_URL;
const REACT_APP_APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;
const REACT_APP_PLAY_STORE_URL = process.env.REACT_APP_PLAY_STORE_URL;

const Footer = () => {
  function renderTitle(title) {
    return <p className="title">{title}</p>;
  }

  function renderLink(child, to) {
    return (
      <p className="link">
        <a href={to} target="_blank">
          {child}
        </a>
      </p>
    );
  }

  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {renderTitle('Get the AverPoint extension')}
            {renderLink('Chrome', REACT_APP_CHROME_EXTENSION_URL)}
            {renderLink('Safari', REACT_APP_SAFARI_EXTENSION_URL)}
            {renderLink('Firefox', REACT_APP_FIREFOX_EXTENSION_URL)}
            {renderLink('Edge', REACT_APP_EDGE_EXTENSION_URL)}
          </div>
          <div className="col-md-4">
            {renderTitle('Get the AverPoint app')}
            {renderLink(
              <img src={AppStore} style={{ width: '150px' }} />,
              REACT_APP_APP_STORE_URL,
            )}
            {renderLink(
              <img src={GooglePlay} style={{ width: '150px' }} />,
              REACT_APP_PLAY_STORE_URL,
            )}
          </div>
          <div className="col-md-4">
            {renderTitle('Company')}
            {renderLink('Contact', 'https://learn.averpoint.com/contact')}
            {renderLink('Blog', 'https://learn.averpoint.com/blogs')}
            {/* {renderLink('Sources', 'sources')} */}
            {/* {renderLink('About', '/about')} */}
            {/* {renderLink('Help', '/help')} */}
            {renderLink('Terms of service', '/pdfview/termsOfService')}
            {renderLink('Privacy Policy', '/pdfview/privacy')}
            {renderLink('Student Data Policy', '/pdfview/studentdatapolicy')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
