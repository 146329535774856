import axios from 'axios';
import qs from 'qs';

import { toast } from 'components/elements/molecules/Toast/Toast';

/*** Constants */
export const LOAD_SOURCE = 'LOAD_SOURCE';
export const LOAD_SOURCE_NAMES = 'LOAD_SOURCE_NAMES';
export const GET_SOURCE = 'GET_SOURCE';
export const GET_SOURCE_SUCCESS = 'GET_SOURCE_SUCCESS';
export const GET_SOURCE_FAIL = 'GET_SOURCE_FAIL';
export const SOURCE_ERROR = 'SOURCE_ERROR';
export const LOAD_SUBSCRIPTION_VERIFICATIONS =
  'LOAD_SUBSCRIPTION_VERIFICATIONS';
export const REMOVE_SUBSCRIPTION_VERIFICATIONS =
  'REMOVE_SUBSCRIPTION_VERIFICATIONS';
export const LOAD_REQUESTED_SOURCES = 'LOAD_REQUESTED_SOURCES';
export const REMOVE_REQUESTED_SOURCES = 'REMOVE_REQUESTED_SOURCES';
export const LOAD_ADMIN_SOURCES = 'LOAD_ADMIN_SOURCES';
export const UPDATE_ADMIN_SOURCE = 'UPDATE_ADMIN_SOURCE';
export const LOAD_ADMIN_ARTICLES = 'LOAD_ADMIN_ARTICLES';
export const REMOVE_ADMIN_ARTICLE = 'REMOVE_ADMIN_ARTICLE';
export const UPDATE_ADMIN_ARTICLE = 'UPDATE_ADMIN_ARTICLE';
export const LOAD_SOURCE_ERRORS = 'LOAD_SOURCE_ERRORS';
export const RESOLVE_SOURCE_ERROR = 'RESOLVE_SOURCE_ERROR';
export const FIX_SOURCE_ERROR = 'FIX_SOURCE_ERROR';

export function sourceError(errorMessage) {
  return {
    type: SOURCE_ERROR,
    errorMessage: errorMessage,
  };
}

export function loadSource(source) {
  return {
    type: LOAD_SOURCE,
    source: source,
  };
}

export function loadSourceList(sourceList) {
  return {
    type: LOAD_SOURCE_NAMES,
    sourceList,
  };
}

export function loadSubscriptionVerifications(subscriptionVerifications) {
  return {
    type: LOAD_SUBSCRIPTION_VERIFICATIONS,
    subscriptionVerifications,
  };
}

export function removeSubscriptionVerifications(subscriptionId) {
  return {
    type: REMOVE_SUBSCRIPTION_VERIFICATIONS,
    subscriptionId,
  };
}

export function loadRequestedSources(requestedSources) {
  return {
    type: LOAD_REQUESTED_SOURCES,
    requestedSources,
  };
}

export function removeRequestedSources(sourceId) {
  return {
    type: REMOVE_REQUESTED_SOURCES,
    sourceId,
  };
}

export function loadAdminSources(adminSources) {
  return {
    type: LOAD_ADMIN_SOURCES,
    adminSources,
  };
}

export function loadAdminArticles(adminArticles) {
  return {
    type: LOAD_ADMIN_ARTICLES,
    adminArticles,
  };
}

export function removeAdminArticles(pointId) {
  return {
    type: REMOVE_ADMIN_ARTICLE,
    pointId,
  };
}

export function updateAdminSource(sourceName, response) {
  return {
    type: UPDATE_ADMIN_SOURCE,
    sourceName,
    response,
  };
}

export function updateAdminArticle(articleMediaCollectionId, componentReviews) {
  return {
    type: UPDATE_ADMIN_ARTICLE,
    articleMediaCollectionId,
    componentReviews,
  };
}

export function loadAdminSourceReadings(sourceName, requestedSources) {
  return {
    type: LOAD_REQUESTED_SOURCES,
    requestedSources,
    sourceName,
  };
}

export function loadSourceErrors(sourceErrors) {
  return {
    type: LOAD_SOURCE_ERRORS,
    sourceErrors,
  };
}

export function handleResolveSourceError(id, resolutionText) {
  return {
    type: RESOLVE_SOURCE_ERROR,
    id,
    resolutionText,
  };
}

export function handleFixSourceError(id) {
  return {
    type: FIX_SOURCE_ERROR,
    id,
  };
}

export function saveSource(source) {
  return (dispatch, getState) => {
    console.log('saving source ' + JSON.stringify(source));

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/saveSource',
        data: {
          source,
        },
      })
        .then((response) => {
          console.log('Save source responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadSource(data.source));
          resolve(data.source);
        })
        .catch((response) => {
          console.log('Save source responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function getSourceList() {
  return (dispatch, getState) => {
    console.log('getting sourceList');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/getSourceList',
        params: {},
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'brackets' });
        },
      })
        .then((response) => {
          console.log('getSourceList responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadSourceList(data.sourceList));
          resolve(data.sourceList);
        })
        .catch((response) => {
          console.log('Save source responded error');
          console.log(response);
          reject(response);
        });
    });
  };
}

export function getSource(sourceId) {
  return (dispatch, getState) => {
    console.log('getting source');

    return new Promise((resolve, reject) => {
      dispatch({
        type: GET_SOURCE,
      });
      axios({
        method: 'get',
        url: `/api/sources/getSource/${sourceId}`,
      })
        .then((response) => {
          console.log('getSource responded successfully');
          console.log(response);
          let data = response.data;
          // dispatch(loadSource(data.source));
          if (data.success) {
            dispatch({
              type: GET_SOURCE_SUCCESS,
              source: data,
            });
            resolve(data);
          } else {
            dispatch({
              type: GET_SOURCE_FAIL,
              error: data.message,
            });
          }
        })
        .catch((response) => {
          console.log('getSource responded error');
          console.log(response);
          dispatch({
            type: GET_SOURCE_FAIL,
            sourceErrorMessage: response,
          });
          reject(response);
        });
    });
  };
}

export function submitSourceRequest(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/submitSourceRequest',
        data,
      })
        .then((response) => {
          resolve(response);
        })
        .catch((response) => {
          console.log('Error', response.message);
          reject(response);
        });
    });
  };
}

export function submitSourceSubscriptionRequest(data) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/submitSourceSubscriptionRequest',
        data,
      })
        .then((response) => {
          toast(
            `Thank you ${data.username}! We'll review, and verify your subscription to ${data.sourceName} soon.`,
          );
          resolve(response);
        })
        .catch((response) => {
          console.log('Error', response.message);
          reject(response);
        });
    });
  };
}

export function getPendingSubscriptionRequests() {
  return (dispatch) => {
    console.log('getting pendingSubscriptionRequests');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/getPendingSubscriptionRequests',
        params: {},
      })
        .then((response) => {
          console.log('pendingSubscriptionRequests responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadSubscriptionVerifications(data));
          resolve();
        })
        .catch((error) => {
          console.log('pendingSubscriptionRequests responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function verifySubscriptionRequest(data) {
  return (dispatch) => {
    console.log('getting verifySubscriptionRequest');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/verifySubscriptionRequest',
        data,
      })
        .then((response) => {
          console.log('verifySubscriptionRequest responded successfully');
          console.log(response);
          dispatch(removeSubscriptionVerifications(data.subscriptionId));
          resolve();
        })
        .catch((error) => {
          console.log('verifySubscriptionRequest responded error');
          console.log(error);
          reject();
        });
    });
  };
}

export function requestedSourcesByTeachers(sourceName) {
  return (dispatch) => {
    console.log('getting requestedSourcesByTeachers');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/courses/requestedSourcesByTeachers',
        params: { searchQuery: sourceName },
      })
        .then((response) => {
          console.log('requestedSourcesByTeachers responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadRequestedSources(data.sources));
          resolve();
        })
        .catch((error) => {
          console.log('requestedSourcesByTeachers responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function getSourceRequests(
  requestType,
  sourceName,
  username,
  approvalStatus,
  page,
  pageSize,
  sortBy,
  sortOrder,
  feature,
  sourceRequestId,
) {
  return (dispatch) => {
    console.log('getting getSourceRequests');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/getSourceRequests',
        params: {
          requestType,
          sourceName,
          page,
          limit: pageSize,
          username,
          approvalStatus,
          sortBy,
          sortOrder,
          feature,
          sourceRequestId,
        },
      })
        .then((response) => {
          console.log('getSourceRequests responded successfully');
          console.log(response);
          let data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.log('getSourceRequests responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function resolveSourceRequest(sourceRequestId, reason, status) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/resolveSourceRequest',
        params: { sourceRequestId, reason, status },
      })
        .then((response) => {
          console.log('resolveSourceRequest responded successfully');
          console.log(response);
          let data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.log('resolveSourceRequest responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function fixHttpPoint(sourceURL) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/diagnosis/fixHttpPoint',
        data: { sourceURL },
      })
        .then((response) => {
          console.log('fixHttpPoint responded successfully');
          console.log(response);
          resolve();
        })
        .catch((error) => {
          console.log('fixHttpPoint responded error');
          console.log(error);
          toast('Error occurred while fixing http article', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function approveSourceRequestedByTeacher(sourceId, status) {
  return (dispatch) => {
    console.log('getting approveSourceRequestedByTeacher');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/courses/approveSourceRequestedByTeacher',
        data: { sourceId, status },
      })
        .then((response) => {
          console.log('approveSourceRequestedByTeacher responded successfully');
          console.log(response);
          toast('Source updated successfully');
          dispatch(removeRequestedSources(sourceId));
          resolve();
        })
        .catch((error) => {
          console.log('approveSourceRequestedByTeacher responded error');
          console.log(error);
          toast('Error occurred while updating source. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function getAdminSources(sourceName, page, limit) {
  return (dispatch) => {
    console.log('getting getAdminSources');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/browserExtensionStatus/getAdminSources',
        params: { sourceName, page, limit },
      })
        .then((response) => {
          console.log('getAdminSources responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadAdminSources(data.response));
          resolve(data);
        })
        .catch((error) => {
          console.log('getAdminSources responded error');
          console.log(error);
          resolve({});
        });
    });
  };
}

export function updateSourceDetails(source) {
  return (dispatch) => {
    console.log('getting updateSourceDetails');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/updateSourceDetails',
        data: { source },
      })
        .then((response) => {
          console.log('updateSourceDetails responded successfully');
          console.log(response);
          let data = response.data;
          toast('Source updated successfully');
          dispatch(updateAdminSource(source.originalSourceName, data.response));
          resolve(data.response);
        })
        .catch((error) => {
          console.log('updateSourceDetails responded error');
          console.log(error);
          toast('Error occurred while updating source. Please try again.', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function getAdminArticles(sourceName) {
  return (dispatch) => {
    console.log('getting getAdminArticles');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/browserExtensionStatus/getNotWorkingArticles',
        params: { sourceName },
      })
        .then((response) => {
          console.log('getAdminArticles responded successfully');
          console.log(response);
          let data = response.data;
          dispatch(loadAdminArticles(data.response));
          resolve();
        })
        .catch((error) => {
          console.log('getAdminArticles responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function updateArticle(
  articleMediaCollectionId,
  componentReviews,
  comment,
) {
  return (dispatch) => {
    console.log('getting updateStatus');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/browserExtensionStatus/updateStatus',
        data: { articleMediaCollectionId, componentReviews, comment },
      })
        .then((response) => {
          console.log('updateStatus responded successfully');
          console.log(response);
          toast('Browser Extension Status updated successfully');
          dispatch(
            updateAdminArticle(articleMediaCollectionId, componentReviews),
          );
          resolve();
        })
        .catch((error) => {
          console.log('updateStatus responded error');
          console.log(error);
          toast(
            'Error occurred while updating Browser Extension Status. Please try again.',
            {
              type: 'error',
            },
          );
          reject();
        });
    });
  };
}

export function getSourceErrorsSummary(
  fromDate,
  toDate,
  sortBy,
  sortOrder,
  page,
  pageSize,
  sourceError,
  sourceName,
) {
  return (dispatch) => {
    console.log('getting getSourceErrorsSummary');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/getSourceErrorsSummary',
        params: {
          fromDate,
          toDate,
          sortBy,
          sortOrder,
          page,
          pageSize,
          sourceError,
          sourceName,
        },
      })
        .then((response) => {
          console.log('getSourceErrorsSummary responded successfully');
          console.log(response);
          let data = response.data;
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getSourceErrorsSummary responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function getSourceActivities(
  fromDate,
  toDate,
  sortBy,
  sortOrder,
  page,
  pageSize,
  sourceName,
) {
  return (dispatch) => {
    console.log('getting getSourceActivities');

    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/sources/getSourceActivities',
        params: {
          fromDate,
          toDate,
          sortBy,
          sortOrder,
          page,
          pageSize,
          sourceName,
        },
      })
        .then((response) => {
          console.log('getSourceActivities responded successfully');
          console.log(response);
          let data = response.data;
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getSourceActivities responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function getSourceErrors(errorIds, fromDate, toDate) {
  return (dispatch) => {
    console.log('getting getSourceErrors');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/getSourceErrors',
        data: { errorIds, fromDate, toDate },
      })
        .then((response) => {
          console.log('getSourceErrors responded successfully');
          console.log(response);
          let data = response.data;
          resolve(data);
        })
        .catch((error) => {
          console.log('getSourceErrors responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function resolveSourceError(id, resolutionText) {
  return (dispatch) => {
    console.log('resolving source error');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/resolveSourceError',
        data: { id, resolutionText },
      })
        .then((response) => {
          console.log('resolveSourceError responded successfully');
          console.log(response);
          toast('Source error resolved successfully');
          dispatch(handleResolveSourceError(id, resolutionText));
          resolve();
        })
        .catch((error) => {
          console.log('resolveSourceError responded error');
          console.log(error);
          toast(
            'Error occurred while resolving source error. Please try again.',
            {
              type: 'error',
            },
          );
          resolve();
        });
    });
  };
}

export function fixSourceError(id) {
  return (dispatch) => {
    console.log('fixed source error');

    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/sources/fixSourceError',
        data: { id },
      })
        .then((response) => {
          console.log('fixSourceError responded successfully');
          console.log(response);
          toast('Source error fixed successfully');
          dispatch(handleFixSourceError(id));
          resolve();
        })
        .catch((error) => {
          console.log('fixSourceError responded error');
          console.log(error);
          toast('Error occurred while fixing source error. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function noteToDeveloperResolved(pointId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/noteToDeveloperResolved', {
          pointId,
        })
        .then((response) => {
          toast('Successfully resolved article issue.');
          dispatch(removeAdminArticles(pointId));
          resolve();
        })
        .catch((error) => {
          console.error('Error while resolving article issue', error);
          toast('Error while resolving article issue. Please try again.', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function calculateSourcesDelta(req) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/calculateSourcesDelta', req)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          toast('Error while uploading sources delta. Please try again.', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function updateSourceLogStatus(req) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/sources/updateSourceLogStatus', req)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          toast('Error while updating source log status. Please try again.', {
            type: 'error',
          });
          reject();
        });
    });
  };
}

export function getSourceStats() {
  return async (dispatch) => {
    try {
      const res = await axios.get('/api/sources/getSourceStats');
      return res.data;
    } catch {
      toast('Error while getting source stats. Please try again.', {
        type: 'error',
      });
      throw null;
    }
  };
}

export function getSources(params) {
  return async (dispatch) => {
    try {
      const res = await axios.get('/api/sources/getSources', { params });
      return res.data;
    } catch {
      toast('Error while getting source stats. Please try again.', {
        type: 'error',
      });
      throw null;
    }
  };
}

export function getSourceFilters(params) {
  return async (dispatch) => {
    try {
      const res = await axios.get('/api/sources/getSourceFilters', { params });
      return res.data;
    } catch {
      toast('Error while getting source stats. Please try again.', {
        type: 'error',
      });
      throw null;
    }
  };
}
