import React, { Fragment } from 'react';

import { Icon, ICONS } from 'components/Icons';

import './TopicTab.scss';
import moment from 'moment';

export function renderCheck(condition, className) {
  if (condition) {
    return (
      <div
        className={`check checked d-inline-flex align-items-center justify-content-center ${className}`}
      >
        <Icon icon={ICONS.CHECK} className="av-white" size={12} />
      </div>
    );
  } else {
    return <div className={`check ${className}`} />;
  }
}

export function isGoalCompletedAfterDueDate(goal) {
  if (!goal.status) {
    return false;
  }

  // We do not update completed property once the due date is passed
  // So, if status is true but completed < target that means,
  // there are some goals completed after due date has been passed.
  return goal.completed < goal.target;
}

export function showReadingDueDates(topic) {
  if (
    !topic.dueDate ||
    !topic.readingStatistics ||
    !topic.readingStatistics.length
  ) {
    return null;
  }

  return (
    <div>
      {topic.readingStatistics.length} readings due on{' '}
      {moment(topic.dueDate).format('MM-DD-YYYY')}
    </div>
  );
}

export function showPromptDueDates(topic, promptType) {
  const isWritingPrompt = promptType === 'writing prompt(s)';
  const promptSettingsVariable = isWritingPrompt
    ? 'writingPromptSettings'
    : 'researchPromptSettings';
  const promptVariable = isWritingPrompt ? 'writingPrompts' : 'researchPrompts';

  if (
    !topic[promptSettingsVariable] ||
    !topic[promptSettingsVariable].dueDate
  ) {
    return null;
  }

  if (!topic[promptVariable] || !topic[promptVariable].length) {
    return null;
  }

  const dueDate = moment(topic[promptSettingsVariable].dueDate).format(
    'MM-DD-YYYY',
  );

  return (
    <div>
      {topic[promptVariable].length} {promptType} due on {dueDate}
    </div>
  );
}

export function checkWritingsDueDatePassed(writingPrompts) {
  if (!writingPrompts || !writingPrompts.length) {
    return true;
  }

  const dueDates = writingPrompts
    .filter((writingPrompt) => writingPrompt.dueDate)
    .map((writingPrompt) => writingPrompt.dueDate);

  if (!dueDates.length) {
    return true;
  }

  return !dueDates.some((dueDate) => moment().isBefore(dueDate));
}

export function renderHeader(
  topic,
  courseName,
  setSelectedCourseId,
  setSelectedTopicId,
  refreshGoalsAndReadings,
  showStudent = false,
  setSelectedStudentId = null,
  setSelectedWritingPromptId,
  selectedWritingPromptId,
  setSelectedReadingId,
  selectedReadingId,
  setSelectedGroupStudentId = null,
) {
  const showTopicPath = () => {
    if (showStudent || selectedWritingPromptId || selectedReadingId) {
      return (
        <small
          className="av-blue mx-2 breadcrumb-link"
          onClick={() => {
            if (setSelectedStudentId) {
              setSelectedStudentId(null);
            }

            if (setSelectedGroupStudentId) {
              setSelectedGroupStudentId(null);
            }

            if (setSelectedWritingPromptId) {
              setSelectedWritingPromptId(null);
            }

            if (setSelectedReadingId) {
              setSelectedReadingId(null);
            }
          }}
        >
          {topic.name}
        </small>
      );
    }

    return <small className="ml-2">{topic.name}</small>;
  };

  const showStudentPath = () => {
    if (showStudent) {
      return (
        <Fragment>
          &gt; <small className="ml-2">{topic.username}</small>
        </Fragment>
      );
    }

    return null;
  };

  function showWritingPromptPath() {
    if (!selectedWritingPromptId) {
      return null;
    }

    return (
      <Fragment>
        &gt; <small className="ml-2">Writing Prompt</small>
      </Fragment>
    );
  }

  function showReadingPromptPath() {
    if (!selectedReadingId) {
      return null;
    }

    return (
      <Fragment>
        &gt; <small className="ml-2">Reading</small>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <p className="mt-4 d-flex align-items-center">
        <small
          className="av-blue mr-2 breadcrumb-link"
          onClick={() => {
            setSelectedCourseId(null);
            setSelectedTopicId(null);

            if (setSelectedStudentId) {
              setSelectedStudentId(null);
            }

            if (setSelectedWritingPromptId) {
              setSelectedWritingPromptId(null);
            }

            if (setSelectedReadingId) {
              setSelectedReadingId(null);
            }
          }}
        >
          Activity
        </small>
        &gt;
        <small
          className="av-blue mx-2 breadcrumb-link"
          onClick={() => {
            setSelectedTopicId(null);

            if (setSelectedStudentId) {
              setSelectedStudentId(null);
            }

            if (setSelectedWritingPromptId) {
              setSelectedWritingPromptId(null);
            }

            if (setSelectedReadingId) {
              setSelectedReadingId(null);
            }
          }}
        >
          {courseName}
        </small>
        &gt; {showTopicPath()}
        {showStudentPath()}
        {showWritingPromptPath()}
        {showReadingPromptPath()}
      </p>
      {!selectedWritingPromptId && !selectedReadingId ? (
        <div className="d-flex align-items-center justify-content-between">
          {!showStudent ? (
            <h3 className="m-0 font-weight-600">{topic.name}</h3>
          ) : (
            <h3 className="m-0 font-weight-600">
              Viewing {topic.username}'s reading activity
            </h3>
          )}
          {!showStudent && (
            <div
              className="mr-3 refresh-button"
              onClick={() => refreshGoalsAndReadings(true)}
            >
              <Icon icon={ICONS.REFRESH} className="refresh-icon mr-2" />
              Refresh
            </div>
          )}
        </div>
      ) : null}
    </Fragment>
  );
}
