import React, { Component } from 'react';
import moment from 'moment';
import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Helmet from 'react-helmet';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';

import { createGlobalStyle, ThemeProvider } from 'styled-components';
import Footer from 'components/layout/Footer/Footer';
import { Header } from './components/layout';
import MainRoutes from './MainRoutes';

import { ToastContainer } from 'components/elements/molecules/Toast/Toast';
import ScrollButton from 'components/elements/molecules/ScrollButton/ScrollButton';
import Hint from 'components/elements/molecules/Hint/Hint';
import PopoverCard from 'components/elements/molecules/PopoverCard/PopoverCard';
import ModalContainer from 'components/modals/ModalContainer';
import CloudinaryWidget from 'components/elements/molecules/Images/CloudinaryWidget';
import ContactButton from 'routes/Contact/ContactButton';
import BottomSheet from 'components/elements/organisms/BottomSheet/BottomSheet';
import SubscribeToNewsletter from 'components/elements/molecules/SubscribeToNewsletter/SubscribeToNewsletter';

import * as Actions from './redux/actions/index';
import { getAppVariables } from './redux/actions/app';
import { getThisWeekAnnouncement } from './redux/actions/mediaLogs';
import { AWSCognitoInstance } from './services/AWSCognito';

// const withDragDropContext = createDndContext(HTML5Backend);

const theme = {
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },
};

/* eslint no-unused-expressions: 0 */

const GlobalStyles = createGlobalStyle`
  html {
    font-size: 62.5%;
  }
  html,
  body {
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    overscroll-behavior: none;
  }

  body {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  }
  h2{
     font-family:'Open Sans';
  }
  p,
  label ,div, span {
    font-family: 'Open Sans', Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }
`;

const REACT_APP_SCROLLING_CONTAINER_ID =
  process.env.REACT_APP_SCROLLING_CONTAINER_ID;

class App extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    const { user } = this.props;

    const parsed = qs.parse(this.props.location.search.substr(1));
    this.setState({
      noHeader: parsed.noHeader,
    });

    this.props.dispatch(getAppVariables());

    const thisWeekAnnouncementShownAt = Number(
      localStorage.getItem('thisWeekAnnouncementShownAt'),
    );

    if (!thisWeekAnnouncementShownAt) {
      this.props.dispatch(getThisWeekAnnouncement());
    } else {
      const thisWeekStartDate = moment().day(0).toDate().getTime();

      // If last announcement shown before start of this week, then fetch again...
      if (thisWeekStartDate > thisWeekAnnouncementShownAt) {
        this.props.dispatch(getThisWeekAnnouncement());
        localStorage.removeItem('thisWeekAnnouncementShownAt');
      }
    }

    // window.analytics.load(process.env.REACT_APP_SEGMENT_WEBSITE);
    // window.analytics.page();
  }

  componentDidUpdate() {
    const { location, user, app } = this.props;
    const parsed = qs.parse(location.search.substr(1));

    // Force logout if requested username does not match with login username
    // Required for CE to sync user between CE and IFrame
    if (
      parsed.username &&
      user &&
      user.username &&
      user.username !== parsed.username
    ) {
      AWSCognitoInstance.logout(user.username);
      this.props.actions.manualLogout(location.pathname);
      return;
    }

    if (
      typeof app.topics === 'object' &&
      Object.keys(app.topics).length === 0 &&
      user.username
    ) {
      this.props.dispatch(getAppVariables());
    }
  }

  renderFooter() {
    const { user } = this.props;

    if (user._id) {
      return null;
    }

    return <Footer />;
  }

  render() {
    let isHeader = true;
    let showContactUs = true;
    const { pathname } = this.props.location;

    if (
      this.props.user &&
      this.props.user.profile &&
      this.props.user.profile.gettingStartedStatus !== 'finished'
    ) {
      console.log('User has not finished getting started');
      if (pathname !== '/welcome') {
        console.log(
          'User is logged in, not started, and not on Welcome page. Redirect.',
        );
        this.props.history.push(`/welcome`);
      }
    }

    if (
      pathname.includes('/PointCard') ||
      pathname.includes('/CollectionCard') ||
      pathname.includes('/embed') ||
      // pathname.includes('/signUp') ||
      // pathname.includes('/login') ||
      pathname.includes('/forgot-password') ||
      pathname.includes('/forgot-username') ||
      pathname.includes('/reset-password') ||
      pathname.includes('/reset-required')
    ) {
      isHeader = false;
    }
    if (
      [
        // '/login',
        '/forgot-username',
        '/forgot-password',
        '/postRegister/success',
        '/postRegister/email-sent',
        '/welcome',
      ].includes(pathname)
    ) {
      isHeader = false;
    }
    if (
      pathname.includes('/PointCard') ||
      pathname.includes('/CollectionCard') ||
      pathname.includes('/embed') ||
      pathname.includes('/popup/addPoint') ||
      pathname.includes('/popup/importPoints') ||
      pathname.includes('/popup/viewWithPoints') ||
      pathname.includes('/about') ||
      pathname.includes('/portfolio/averpoint') ||
      pathname.includes('/popup/manageclaim') ||
      pathname.includes('/popup/articleQuiz')
    ) {
      showContactUs = false;
    }

    const style = { width: '100vw' };
    if (
      !pathname.includes('/PointCard') &&
      // !pathname.includes('/CollectionCard') &&
      !pathname.includes('/embed')
    ) {
      style.height = '100vh';
    }

    // wait for session to initialize before rendering main routes.
    return (
      <>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          <DndProvider backend={Backend}>
            <div style={style} id={REACT_APP_SCROLLING_CONTAINER_ID}>
              <Helmet titleTemplate="%s - Averpoint" defaultTitle="Averpoint" />
              {this.state.noHeader ? null : isHeader && <Header />}
              {this.props.initializing ? (
                <div>Initializing AverPoint...</div>
              ) : (
                <React.Fragment>
                  <MainRoutes />
                  <BottomSheet />
                  <ModalContainer />
                  {this.renderFooter()}
                  <ToastContainer />
                  <ScrollButton />
                  {showContactUs && <ContactButton />}
                  <CloudinaryWidget />
                  <Hint />
                  {/* <SubscribeToNewsletter /> */}
                  <PopoverCard />
                </React.Fragment>
              )}
            </div>
          </DndProvider>
        </ThemeProvider>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  initializing: state.user.initializing,
  user: state.user ? state.user.user : null,
  app: state.app ? state.app : {},
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    dispatch,
  };
}

App = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

export default App;
// export default withDragDropContext(App);
