import * as Actions from '../actions/index';

export default function subscriptions(
  state = {
    subscriptions: [],
  },
  action,
) {
  switch (action.type) {
    case Actions.LOAD_SUBSCRIPTIONS: {
      const subscriptions = JSON.parse(JSON.stringify(state.subscriptions));

      return {
        ...state,
        subscriptions: [...subscriptions, ...action.subscriptions],
      };
    }
    default:
      return state;
  }
}
