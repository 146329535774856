/* eslint-disable no-else-return */
/* eslint-disable react/button-has-type */
import React, { useEffect, useReducer, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { bindActionCreators } from 'redux';
import * as Actions from 'redux/actions/index';
import { Icon, ICONS } from 'components/Icons';
import QTPQuestions from 'helpers/QTP.json';
import Survey from 'components/elements/molecules/Survey/Survey';
import QTPRTPGroupsSelection from '../QTPRTPGroupsSelection/QTPRTPGroupsSelection';
import RectangularButton from 'components/elements/molecules/RectangularButton/RectangularButton';

function QuestionPointForm({
  actions,
  close,
  point,
  user,
  className,
  selectedCaptions,
  qtp,
  rid,
  collectionPoint,
  isMock = false,
  captions,
  courseId,
}) {
  const [state, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'QUESTION_POINT_SUBMIT':
          return {
            ...state,
            submitting: true,
          };
        case 'QUESTION_POINT_SUBMIT_SUCCESS':
          return {
            ...state,
            submitting: false,
          };
        case 'SET_SURVEY_INDEX':
          return {
            ...state,
            surveyIndex: action.payload,
          };
        case 'SET_SURVEY_MODEL':
          return {
            ...state,
            surveyModel: action.payload,
          };
        case 'SET_SHOW_SURVEY':
          return {
            ...state,
            showSurvey: action.payload,
          };
        case 'SHOW_GROUPS_SELECTION':
          return {
            ...state,
            showGroupsSelection: true,
          };
        default:
          return state;
      }
    },
    {
      showSurvey: true,
      submitting: false,
      why: '',
      evidence: '',
      showConfirm: false,
      surveyIndex: 0,
      surveyModel: {},
      oldSurveyModel: qtp ? { ...qtp.surveyResponse } : undefined,
      showGroupsSelection: false,
    },
  );

  const surveyRef = useRef(null);

  async function onQuestionPoint() {
    actions.showHint(
      'info',
      qtp ? 'Updating your question' : 'Adding your question to the claim',
    );
    dispatch({ type: 'QUESTION_POINT_SUBMIT' });
    let mediaClaim = point;

    if (!mediaClaim._id) {
      // mediaClaim hasn't been created yet
      point.isMock = isMock;
      const claim = {
        mediaClaimPoint: point,
        articleMediaCollection: collectionPoint,
        context: 'QTP',
      };
      const res = await actions.createMediaClaim(claim);
      mediaClaim = res.mediaClaimPoint;
    }

    if (mediaClaim) {
      const newQTP = {
        pointId: mediaClaim._id,
        userId: user._id,
        surveyResponse: {
          ...state.surveyModel,
          captions: selectedCaptions,
          // note: state.note,
          rid,
        },
        isMock,
        articleMediaCollectionId: collectionPoint
          ? collectionPoint._id
          : undefined,
      };

      if (qtp) {
        newQTP._id = qtp._id;
      }

      const res = await actions.addQTP(newQTP);
      dispatch({ type: 'QUESTION_POINT_SUBMIT_SUCCESS' });

      if (res.qtp._id && collectionPoint && collectionPoint.groups.length) {
        dispatch({ type: 'SHOW_GROUPS_SELECTION' });
      } else {
        close(true, res.qtp);
      }

      if (qtp && qtp._id) {
        const interactionReview = {
          interactionId: qtp._id,
          interactionType: 'QTP',
          comment: '',
          status: 'Pending',
        };
        await actions.addInteractionReview(interactionReview);
        await actions.getInteractionReviews(qtp, 'QTP', point);
      }
    } else {
      console.log(
        "We tried everything we could do, but the claim point didn't get created.",
      );
      close(false);
    }
    actions.hideHint();
  }

  function renderClose() {
    return (
      <div className="pl-3 pr-3">
        <div className="av-blue d-flex align-items-center mb-3">
          <button
            className="btn btn-clear btn-close av-blue ml-auto p-0"
            onClick={() => close(false)}
          >
            <Icon icon={ICONS.CLOSE} />
          </button>
        </div>
      </div>
    );
  }

  function renderSurvey() {
    if (!state.showSurvey) {
      return null;
    }

    return (
      <div className="">
        <Survey
          questions={QTPQuestions}
          onFinish={onQuestionPoint}
          className="mt-4 pl-3 pr-3"
          onChangeIndex={(index) =>
            dispatch({ type: 'SET_SURVEY_INDEX', payload: index })
          }
          onChangeModel={(model) =>
            dispatch({ type: 'SET_SURVEY_MODEL', payload: model })
          }
          oldModel={state.oldSurveyModel}
          ref={surveyRef}
          startIndex={
            collectionPoint ? (captions && captions.length === 1 ? 1 : 0) : 1
          }
        />
      </div>
    );
  }

  function renderForm() {
    return <React.Fragment>{renderSurvey()}</React.Fragment>;
  }

  function renderContinueButton() {
    const disabled =
      !(surveyRef.current && surveyRef.current.allowContinue()) ||
      state.submitting;
    const buttonText =
      surveyRef.current && surveyRef.current.getActiveQuestion().finalQuestion
        ? qtp
          ? 'Update'
          : 'Submit'
        : 'Continue';

    return (
      <RectangularButton
        className="ml-auto mr-3"
        onClick={() => {
          surveyRef.current && surveyRef.current.continue();
        }}
        disabled={disabled}
      >
        {buttonText}
        <Icon icon={ICONS.ARROW_FORWARD} className="ml-2" />
      </RectangularButton>
    );
  }

  function renderFooter() {
    let surveyIndex = surveyRef.current ? state.surveyIndex + 1 : 1;
    let totalSteps = 3;

    if (!captions || captions.length === 1) {
      surveyIndex -= 1;
      totalSteps -= 1;
    }

    let elm = (
      <React.Fragment>
        <button
          className="btn bg-transparent gray-4 d-inline-flex align-items-center font-weight-600"
          type="button"
          onClick={() => close(false)}
        >
          <Icon icon={ICONS.CLOSE} className="mr-2" /> Back
        </button>
        <div className="av-blue ml-auto" style={{ opacity: 0.3 }}>
          {surveyIndex} of {totalSteps}
        </div>
        {renderContinueButton()}
      </React.Fragment>
    );

    return (
      <div
        className="d-flex pt-4 align-items-center mt-4"
        style={{ borderTop: '1px solid #C0CFDA' }}
      >
        {elm}
      </div>
    );
  }

  function renderReviewGroups() {
    return (
      <QTPRTPGroupsSelection
        type="QTP"
        point={point}
        collectionPoint={collectionPoint}
        close={() => close(true)}
      />
    );
  }

  function renderQTPForm() {
    return (
      <Fragment>
        {renderForm()}
        {renderFooter()}
      </Fragment>
    );
  }

  const containerClassName = classnames(className, 'question-point-form');

  return (
    <div className={containerClassName}>
      {state.showGroupsSelection ? renderReviewGroups() : renderQTPForm()}
      {renderClose()}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.user,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(Actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPointForm);
