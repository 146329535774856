/* eslint-disable func-names */
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce } from 'throttle-debounce';
import * as Actions from '../../../redux/actions/index';

import InvitePointContainer from './InvitePointContainer';
import { Modal } from 'react-bootstrap';
import { Icon, ICONS } from '../../Icons';
import { toast } from 'components/elements/molecules/Toast/Toast';

require('./css/InvitePointModal.scss');

const REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID =
  process.env.REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID;

class InvitePointModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
      error: {},
      users: [],
      searchTerm: '',
    };

    // Debounced function to avoid unnecessary API calls
    this.debouncedGetAllUsers = debounce(500, this.getAllUsers.bind(this));
  }

  componentDidMount() {}

  async getAllUsers() {
    try {
      const response = await this.props.actions.getAllUsers(
        this.state.searchTerm,
      );
      this.setState({ users: response });
      return response;
    } catch (error) {
      console.log('Error trying to get all users ' + error.message);
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      nextState.searchTerm !== this.state.searchTerm &&
      nextState.searchTerm.length > 2
    ) {
      this.debouncedGetAllUsers();
    }
  }

  componentWillUnmount() {
    this.debouncedGetAllUsers && this.debouncedGetAllUsers.cancel();
  }

  onSave() {
    if (this.validate()) {
      this.props.onAction(this.props.onSave, this.props.model).then(() => {
        this.props.onClose(true);
      });
    }

    this.setState({
      isSubmitted: true,
    });
  }

  onInvite = async (authors, permissionLevel, inviteOption) => {
    const { type, groupId, point } = this.props.model;

    if (this.props.onInviteToMultiplePoints) {
      this.props.onInviteToMultiplePoints(authors, permissionLevel);
      this.props.onClose(true);
      return;
    }

    this.setState({ isSending: true });
    let response;

    if (type === 'GROUP') {
      response = await this.props.onInvite(groupId, authors);
    } else {
      response = await this.props.onAction(
        this.props.onInvite,
        point,
        authors,
        permissionLevel,
        inviteOption,
      );
    }

    this.props.onClose(true);
  };

  onClose = () => {
    this.props.onClose(false);
  };

  setSearchTerm = (searchTerm) => {
    this.setState({ searchTerm });
  };

  render() {
    let container = document.getElementById(
      REACT_APP_FULL_SCREEN_MODAL_CONTAINER_ID,
    );

    let { point, type } = this.props.model;

    if (type === 'GROUP') {
      type = 'Group';
    } else {
      type = point.isCollection ? 'Collection' : 'Point';
    }

    return (
      <Modal show={true} className="invitePoint" container={container}>
        <Modal.Header>
          <div className="d-inline-flex align-items-center font-weight-bold">
            <Icon icon={ICONS.ADD} className="mr-2" size={16} />
            <span>Invite users to join this {type}</span>
          </div>
          <div className="pull-right av-grayish-cyan" onClick={this.onClose}>
            <i className="fa fa-times" aria-hidden="true" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <InvitePointContainer
            point={this.props.model.point}
            onInvite={this.onInvite}
            userList={this.state.users}
            isSending={this.state.isSending}
            type={this.props.model.type}
            searchTerm={this.state.searchTerm}
            setSearchTerm={this.setSearchTerm}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const authenticated = state.user.authenticated;
  const user = state.user.user;

  let existingAuthorUsernames = [];
  if (ownProps.model && ownProps.model.point) {
    existingAuthorUsernames = ownProps.model.point.authors.map(
      (author) => author.username,
    );
  }

  // const userList =
  //   state.user.userList &&
  //   state.user.userList.filter(function(item, index) {
  //     //if (item.username != user.username) return true;

  //     return existingAuthorUsernames.indexOf(item.username) === -1;
  //   });
  let portfolio;
  if (authenticated) {
    let portfolioObject = state.portfolios[user.username];
    if (portfolioObject) portfolio = portfolioObject.portfolio;
  }

  return {
    authenticated,
    user,
    portfolio,
    // userList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Actions, dispatch),
    openErrorModal: (type, message) => {
      var title = 'Error';
      let props = {
        model: {
          title,
          message,
        },
      };
      dispatch(Actions.openModal('error', props));
    },
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InvitePointModal);
