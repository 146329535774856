import axios from 'axios';
import { toast } from 'components/elements/molecules/Toast/Toast';

export const LOAD_SUBSCRIPTIONS = 'LOAD_SUBSCRIPTIONS';

export function loadSubscriptions(subscriptions) {
  return {
    type: LOAD_SUBSCRIPTIONS,
    subscriptions,
  };
}

export function getActiveSubscriptions(token, dispatch) {
  return axios({
    method: 'get',
    url: '/api/subscriptions/getActiveSubscriptions',
    headers: {
      authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      console.log('getActiveSubscriptions responded successfully');
      console.log(response);
      dispatch(loadSubscriptions(response.data.subscriptions));
    })
    .catch((error) => {
      console.log('getActiveSubscriptions responded error');
      console.log(error);
    });
}

export function syncActiveSubscriptions() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/subscriptions/getActiveSubscriptions',
        data: {},
      })
        .then((response) => {
          console.log('getActiveSubscriptions responded successfully');
          console.log(response);
          dispatch(loadSubscriptions(response.data.subscriptions));
          resolve();
        })
        .catch((error) => {
          console.log('getActiveSubscriptions responded error');
          console.log(error);
          resolve();
        });
    });
  };
}

export function createCheckoutSession(productType) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: '/api/payments/createCheckoutSession',
        data: { productType },
      })
        .then((response) => {
          console.log('createCheckoutSession responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('createCheckoutSession responded error');
          console.log(error);
          toast('Error while creating payment session', {
            type: 'error',
          });
          resolve();
        });
    });
  };
}

export function getCheckoutSessionDetails(sessionId) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: '/api/payments/getCheckoutSessionDetails',
        params: { sessionId },
      })
        .then((response) => {
          console.log('getCheckoutSessionDetails responded successfully');
          console.log(response);
          resolve(response.data);
        })
        .catch((error) => {
          console.log('getCheckoutSessionDetails responded error');
          console.log(error);
          reject(error);
        });
    });
  };
}
